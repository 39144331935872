import { useState } from "react";
import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';


function BalanceSheet(Borrower){

    const [periodOfLoan, setPeriodOfLoan] = useState(0);
    const [projectedAmount, setProjectedAmount] = useState([]);
    const [newProjectedAmount, setNewProjectedAmount] = useState([]);

    const [repaymentVariables, setRepaymentVariables] = useState({});
    const [periodAndMonthArr, setPeriodAndMonthArr] = useState([]);
    const [emiResponse, setEmiResponse] = useState({});

    const [isShowDenialMessage, SetIsShowDenialMessage] = useState(false);

    const [isShowSelectBorrowerMessage, SetIsShowSelectBorrowerMessage] = useState(false);
    const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);

    const [FAssetsTable, setFAssetsTable] = useState([]);
    const [isRefreshButton, SetIsRefreshButton] = useState(false);
    const [flagRefresh, SetFlagRefresh] = useState(false);
    // let [fixedAssets, SetFixedAssets] = useState([]);
    
    const [tabData, SetTabData] = useState([]);
    const [netProfit, SetNetProfit] = useState([]);
    const [provisionForTax, SetProvisionForTax] = useState([]);
    const [drawings, SetDrawings] = useState([]);
    // const [drawings, setDrawings] = useState('');
    const [ownCapital, setOwnCapital] = useState('');
    const [depreciation, SetDepreciationAllowable] = useState([]);
    const [borrowerProfile, SetBorrowerProfile] = useState({});
    // const [val, setVal] = useState([]);
    const [closingCash, SetClosingCash] = useState([]);
    const [buttonFlag, SetButtonFlag] = useState(false);
    const [IsShowButton, SetIsShowButton] = useState(true);

    const balanceSheetRow = ["CURRENT LIABILITIES :", "1) Short Term Bank Borrowings OR Term Loan(including CP) Cash Credit", "a) From Bank",
        "b) From Other Banks", "2) SUB-TOTAL (a+b)", "", "3) Other Short Term Loans & borrowings", "4) Deposits (maturing in 1 year)",
        "5) Unsecured Loans( Loan from members )", "6) Advances/Progress Payments from Customers/Deposits Received", "7) Statutory Liabilities (due in one year)",
        "8) Installments of term loans (setup) Installment for construction of building and the labs(due in one year)", "9) Other Current Liabilities and Provisions",
        "10) SUB-TOTAL (Sum 3 to 9)", "", "11) Sundry Creditors", "12) Interest Accrued but Not Due (at end of month)", "13) Provision for Tax",
        "14) Provision for Dividend", "", "15) TOTAL CURRENT LIABILITIES (2 + 10 + 11 + 12 + 13 + 14)", "TERM LIABILITIES :", "16) Debentures (not maturing in 1 year)",
        "17) Redeemable Preference Share Capital ( maturing after 1 but before 12 years)", "18) Term Loans (repayable after 1 year)", "19) Borrowings as unsecured loan",
        "20) Deferred Payment Credit", "21) Term Deposits repayable after 1 year", "22) Creditors for Capital Goods", "", "23) TOTAL TERM LIABILITIES (SUM 16 TO 22)", "NETWORTH", "24) Equity Share Capital (Opening Capital)", "Addition Of Capital", "25) Reserves", "26) General Reserve", "27) Development Rebate Reserve/Investment Allowance",
        "28) Share Application Memory", "29) Surplus (+) or Deficit (-) in P & L A/c", "Less: Drawing", "30) NETWORTH ( Sum 24 To 29)", "31) TOTAL LIABILITIES (15 + 23 + 30)",
        "ASSETS", "CURRENT ASSETS :", "32) Receivables other than deferred and export receivable (due in 1 year) (Debtors) & Advances", "33) Export Receivables",
        "34) TOTAL DEBTORS [32 + 33]", "", "35) Raw Material - Imported", "36) Raw Material - Indigenous", "37) Work-in-Process", "38) Finished Goods", "39) Finished goods (in transit)",
        "40) TOTAL INVENTORIES ( Sum 35 to 39 )", "41) Investments (other than long term investments eg. Sinking Fund, Gratuity Fund etc.)", "a) Govt. & other trustee securities", "b) FDs in Banks",
        "42) Interest Accured and Due and Installments of deferred receivables ( due within 1 year)", "43) Investments ( other than long term investments eg. Sinking Fund, Gratuity Fund etc.)",
        "a) Govt. & other trustee securities", "44) Advances to Staff and for land and receivables", "45) Other Current Assets (Advances and deposits)", "46) SUB-TOTAL : OTHER CURRENT ASSETS (EXCLUDING CASH & BANK BALANCE) (Sum 41 to 45)",
        "47) Advance Tax Paid", "48) Cash Balances & Bank balances", "", "49) TOTAL CURRENT ASSETS (34 + 40 + 46 + 47 + 48)", "FIXED ASSETS :", 
        "50) Gross Block excluding Revalued Assets", "51) ADDITION", "52) GROSS BLOCK (50 + 51)", "53) Accumulated Depreciation", "54) NET BLOCK (52 - 53)",
        "55) Capital Work in Progress", "56) NET FIXED ASSETS (54 + 55)", "57) Investments/Book Debts/Advances/Deposits which are not Current Assets :",
        "a) SECURITY DEPOSITS(FDR) & accrued interest", "b) ADVANCES", "OTHER NON-CURRENT ASSETS  :", "58) Advances to suppliers of Goods, Spares and Contractors Non refundable",
        "59) Deferred Receivables maturing beyong one year", "60) Non-Consumable Stores", "61) Other Miscellaneous Assets including dues from Directors", 
        "62) TOTAL NON-CURRENT ASSETS(Sum 58 to 61)", "63) Infangible Assets", "64) Total Fixed Assets (49 + 56 + 57 + 62 + 63)", "65) TANGIBLE NETWORTH (30 - 63)",
        "66) TOTAL OUTSIDE LIABILITIES (15 + 23)", "DIFFERENCE(31 - 64)"    
    ];

    let borrowerDetailProjectItem = {};
borrowerDetailProjectItem.BorrowerId = Borrower.BorrowerId;
const contentRef = useRef();
const generatePDF = () => {
    const input = contentRef.current; // Capture the DOM element

    const options = {
        margin: 0.2,
        filename: 'BalanceSheet.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(options).from(input).save();

    // Convert the content to canvas using html2canvas
    // html2canvas(input, {scale:2}).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('landscape', 'mm', 'a4');
    //   const imgWidth = 270; // Width of the A4 page in mm
    //   const pageHeight = 315; // Height of A4 page in mm
    //   const imgHeight = 2*(canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   let position = 0;

    //   // Add the image to the PDF
    //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   // If the content exceeds one page, add additional pages
    //   while (heightLeft > 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }

    //   // Save the PDF file
    //   pdf.save('BalanceSheet.pdf');
    // });
  };

async function assetsDetail(){

    let reqBody = {};
    reqBody.BorrowerId = Borrower.BorrowerId;
    const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getFixedAssets",{method:"POST",
    mode:"cors",
    headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody)
}).then
((response) =>
{ 
 console.log(response);
 if(response.ok){
   console.log("Success");
 }else{
   console.log("Failure");
 }
 return response.json();
}
).then((data) => {
 console.log(data.body);
 if(data.Message === "Success"){
   console.log("Data: Success");
 }
//  SetFixedAssets(data["Fixed Assets"]);
 setPeriodOfLoan(data.periodOfLoan);
 const fassetTable = [];
 for(let i = 0; i < periodOfLoan; i++){

    fassetTable.push(i);
    // setFAssetsTable(fassetTable);
    // console.log(FAssetsTable);
 }
 console.log(periodOfLoan);
 let fa = [...data["Fixed Assets"]];
       for(let i = 0; i < fa.length; i++){
        if(emiResponse.gstItc === 'No'){
        fa[i].total = parseFloat((parseFloat((fa[i].total).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        fa[i].additionsNote_A = parseFloat((parseFloat((fa[i].additionsNote_A).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        fa[i].wdv_Closing = parseFloat((parseFloat((fa[i].wdv_Closing).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        }
       }
       let FAssetsArray = fassetTable.map(element => (
          element = [...fa]
       ))
//  let FAssetsArray = fassetTable.map(element => (
//     element = [...data["Fixed Assets"]]
//  ))
 setFAssetsTable(FAssetsArray);
  console.log(FAssetsArray);
 return data;
});

console.log(response);
}

const getDepreciationAllowable = async () => {

    try {
        // console.log(Borrower.BorrowerId);
        if(Borrower.BorrowerId !== -1){
          let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getDepreciationValues",{method:"POST",
          mode:"cors",
          headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
      }).then
      ((response) =>
      { 
       console.log(response);
       if(response.ok){
         console.log("Success");
       }else{
         console.log("Failure");
       }
       return response.json();
      }
      ).then((data) => {
      console.log(data.Message, data);
      let depreciation = data.Depreciation;
      if(depreciation === '' || depreciation === null){
        SetIsShowDenialMessage(true);
        
      }
      else{
        depreciation = JSON.parse(depreciation);
        
      }  
      SetDepreciationAllowable(depreciation);
    //   console.log(depreciationAllowable);
      });
      console.log(response);
        }else{
          SetIsShowSelectBorrowerMessage(true);
        }
      } catch (error) {
        alert(error);
      }
}

async function saveBalanceSheetDetails(){

    try {
        if(Borrower.BorrowerId !== -1){
            let reqBody = {};
            reqBody.BorrowerId = Borrower.BorrowerId;
            // reqBody.drawings = drawings;
            reqBody.ownCapital = ownCapital;
            const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/saveBalanceSheetDetail',{
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody)
            });
        //   debugger
            if(response.ok){
              const data = await response.json();
              if(data.Message === "Success") {
                alert('Values saved successfully');
              }
            }
        }else{
            SetIsShowSelectBorrowerMessage(true);
          }
    } catch (error) {
        alert(error)
    }
  }

async function getOperatingStatementDetails(){
    try {
        if(Borrower.BorrowerId !== -1){
            let reqBody = {};
            reqBody.BorrowerId = Borrower.BorrowerId;
            const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getOperatingStatementDetail',{
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody)
            });
          
            if(response.ok){
              const data = await response.json();
            // debugger
              if(data.Message === "Success") {
                let netProfit = data.NetProfit;
                let provisionForTax = data.ProvisionForTax;
              if(netProfit === '' || netProfit === null || provisionForTax === '' || provisionForTax === null){
                SetIsShowDenialMessage(true);
              }else{
                netProfit = JSON.parse(netProfit);
                SetNetProfit(netProfit);
                provisionForTax = JSON.parse(provisionForTax);
                SetProvisionForTax(provisionForTax);
                SetIsRefreshButton(true);
              }
              }else if(data.Message === "Failure") {
                // SetNetProfit(JSON.parse(data.NetProfit));
                SetIsShowDenialMessage(true);
                SetIsRefreshButton(false);
              }
            }
        }else{
            SetIsShowSelectBorrowerMessage(true);
          }
    } catch (error) {
        alert(error);
    }
  }


    async function periodOfLoanf() {

        try {
            if(Borrower.BorrowerId !== -1){
                const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getPeriodOfLoan', {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(borrowerDetailProjectItem)
                });
            
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    if (data.Message === "Success") {
                        setPeriodOfLoan(data.PeriodOfLoan);
                        console.log(data.PeriodOfLoan);
                        let UpdateProjectedAmount = ["LIABILITIES", "Estimated Rs"];
                        for (let i = 0; i < data.PeriodOfLoan; i++) {
                            UpdateProjectedAmount.push("Projected Rs");
                        }
                        setProjectedAmount(UpdateProjectedAmount);
                        // for(let i = 0; i < data.PeriodOfLoan; i++){
                        //     periods.push(i)
                        // }
                        
                    } else {
                        setPeriodOfLoan(0);
                    }
                } else {
                    console.log("Failure");
                }
                console.log("PeriodOfLoan: "+ periodOfLoan);
        
                const responseRepaymentSchedule = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getEmiPeriod",{method:"POST",
                    mode:"cors",
                    headers: {
                      "Content-Type": "application/json",
                      },
                      body: JSON.stringify(borrowerDetailProjectItem)
                }).then((response) =>
               { 
                console.log(response);
                if(response.ok){
                  console.log("Success");
                }else{
                  console.log("Failure");
                }
                return response.json();
               }
              ).then((data) => {
                console.log(data.body);
                if(data.Message === "Success"){
                  console.log("Data: Success");
                  setRepaymentVariables(data.sourceOfFunds);
                setPeriodAndMonthArr(data.PeriodAndMonth);
                setEmiResponse(data.EmiResponse);
                
                SetIsShowDenialMessage(false);
                }else if(data.Message === 'Data Not Found'){
                    SetIsShowDenialMessage(true);
                }
                
                return data;
              });
                console.log(responseRepaymentSchedule);
                // getNetProfit();
                getUserProfile();
            }else{
                SetIsShowSelectBorrowerMessage(true);
              }
        } catch (error) {
            alert(error)
        }
        

    }

    const getUserProfile = async () => {
        try {
            if(Borrower.BorrowerId !== -1){
                let reqObj = {};
                reqObj.BorrowerId = Borrower.BorrowerId;
      
                const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getBorrowerProfile", {method: 'POST',
                    mode: 'cors',
                    headers:{
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(reqObj)
            }).then((response) => {
                // debugger
                if(response.ok)
                return response.json();
            }).then((data) => {
                if(data.Message === 'Success'){
                    SetBorrowerProfile(data.Profile);
                }else if(data.Message === 'Failure'){
                    SetIsShowNoDataMessage(true);
                }
            });
      
            console.log(response);
            }else{
              SetIsShowSelectBorrowerMessage(true);
            }
        } catch (error) {
            alert(error);
        }
      }


      function closeNoDataModal(){
        SetIsShowNoDataMessage(false);
      }

    function closeMessageModal(){
        SetIsShowSelectBorrowerMessage(false);
      }

    function closeDenialMessageModal(){
        SetIsShowDenialMessage(false);
      }

    async function getCashFlowDetails(){
        try {
            if(Borrower.BorrowerId !== -1){
                let reqBody = {};
                reqBody.BorrowerId = Borrower.BorrowerId;
                const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getCashFlowDetail',{
                  method: "POST",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(reqBody)
                });
                // debugger
                if(response.ok){
                  const data = await response.json();
                  if(data.Message === "Success") {
                    SetClosingCash(JSON.parse(data.ClosingCash));
                    SetDrawings(JSON.parse(data.Drawings));
                  }else if(data.Message === "Failure") {
                    // SetClosingCash(JSON.parse(data.ClosingCash));
                    alert('No Cash Flow Values Found!!');
                  }
                }
            }else{
                SetIsShowSelectBorrowerMessage(true);
              }
        } catch (error) {
            alert(error);
        }
      }

    const settableData = () => {
        try {
            if(Borrower.BorrowerId !== -1){
                let tab = [];
                let principalAmounts = periodAndMonthArr.map((element) => (element.PrincipalAmount));
                let outStandingValue = periodAndMonthArr.map((element) => (element.TotalOutstandingValue));
                let projectAmount = [];
                for(let i = 0; i < projectedAmount.length; i++){
                    if(i <= 1)
                    projectAmount[i] = projectedAmount[i];
                    else{
                        projectAmount[i] = projectedAmount[i] + i;
                    }
                }
                setNewProjectedAmount(projectAmount);
                console.log(projectAmount);
                console.log(projectedAmount);
                console.log(principalAmounts);
                console.log(repaymentVariables);
               
                for(let i = 0; i < balanceSheetRow.length; i++){
                   
                    let rowObj = {};
        
                    for(let k = 0; k < projectedAmount.length; k++){   // && j < principalAmounts.length
                        // let tabA = [...tabData];
                        let value = projectAmount[k];
                        rowFinding(i, k);
                        function rowFinding(i, k){
                            if(k > 0){
                                rowObj[value] = parseFloat(0);
                            }else if(k===0){
                                rowObj[value] = balanceSheetRow[i];
                            }if((i === 0 || i === 5 || i === 14 || i === 19 || i === 21 || i === 29 || i === 31 || i === 42 || i === 43 || i === 47 || i === 67) && k !== 0){  //|| i === 75
                                rowObj[value] = " ";
                            }if ((i === 11) && k >= 1 ) {  //|| i === 13 || i === 20
                                rowObj[value] = parseFloat(parseFloat((principalAmounts[k - 1])).toFixed(2));
                             }else if(i === 17 && k >= 1){
                                rowObj[value] = parseFloat(parseFloat(provisionForTax[k]).toFixed(2));
                             }else if((i === 24) && k >= 1 ){    //|| i === 30  && j < principalAmounts.length - 1
                               if((k) < projectedAmount.length){
                                if(k === projectedAmount.length - 1){
                                    rowObj[value] = 0;
                                }else{
                                rowObj[value] = parseFloat(parseFloat((outStandingValue[k - 1])).toFixed(2));
                                }
                                }
                            // j++;
                        }else if(i === 33 && k === 1){
                            rowObj[value] = parseFloat(repaymentVariables["marginContribution"].toFixed(2));
                        }
                        else if(i === 38 && k >= 1){
                            rowObj[value] = parseFloat(parseFloat((netProfit[k])).toFixed(2));
                        }else if(i === 39 && k >= 1){
                            rowObj[value] = parseFloat(parseFloat((drawings[k])).toFixed(2));
                        }
                        else if(i === 71 && k > 0){
                            // debugger
                            getDepreciationAllowable();
                            rowObj[value] = parseFloat(depreciation[k - 1].toFixed(2));
                        }else if(i === 69 && k === 1){
                            // debugger
                            // assetsDetail();
                            let additions = FAssetsTable.map((FAssetsElement, _point) => (parseFloat(FAssetsElement.reduce((element, curr) => curr.additionsNote_A + element, 0)).toFixed(2)));
                            rowObj[value] = parseFloat(parseFloat(additions[k - 1]).toFixed(2));
                            // handleFixedAssets();
                            // let rowObjA = tabA[68];
                            // let rowObjB = tabA[69];
                            // let totalA = tabA[70];
                            // if(k === 1){
                            //     totalA["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                            // }else{
                            //     totalA["Projected Rs" + (k + 1)] = parseFloat(rowObjA["Projected Rs" + (k + 1)]) + parseFloat(rowObjB["Projected Rs" + (k + 1)]); //+ parseFloat(total["Projected Rs" + k]);
                            // }
                            // let rowObjC = tabA[70];
                            // let rowObjD = tabA[71];
                            // let totalC = tabA[72];
                            // if(k === 1){
                            //     totalC["Estimated Rs"] = parseFloat(rowObjC["Estimated Rs"]) - parseFloat(rowObjD["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                            // }else{
                            //     totalC["Projected Rs" + (k + 1)] = parseFloat(rowObjC["Projected Rs" + (k + 1)]) - parseFloat(rowObjD["Projected Rs" + (k + 1)]); //+ parseFloat(total["Projected Rs" + k]);
                            // }
                            // let rowObjE = tabA[72];
                            // let rowObjF = tabA[73];
                            // let totalE = tabA[74];
                            // if(k === 1){
                            //     totalE["Estimated Rs"] = parseFloat(rowObjE["Estimated Rs"]) + parseFloat(rowObjF["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                            // }else{
                            //     totalE["Projected Rs" + (k + 1)] = parseFloat(rowObjE["Projected Rs" + (k + 1)]) + parseFloat(rowObjF["Projected Rs" + (k + 1)]); //+ parseFloat(total["Projected Rs" + k]);
                            // }
                            // if(k === 1){
                            //     rowObjA["Projected Rs" + (k + 1)] = totalE["Estimated Rs"];
                            //     console.log(rowObjA);
                            // }else if(k > 1 && k < projectedAmount.length){
                            //     rowObjA["Projected Rs" + (k + 1)] = totalE["Projected Rs" + k];
                            // }
                            // SetTabData(tabA);
                        }
                        // console.log(rowObj, "hello");
                    }            
                    }
                    // tab.push(rowObj);
                    tab.push(rowObj);
                }
                
                SetTabData(tab);
                console.log("tabData: "+tab)
            }else{
                SetIsShowSelectBorrowerMessage(true);
            }
        } catch (error) {
            alert(error);
        }
    }

        
    // function handleChange(e, indice){
    //     let values = [...val];
    //     values[indice] = parseFloat(e.target.value);
    //     setVal(values);
    //     console.log(val);
    //     let tab = [...tabData];
    //     let rowObjtop = {};
    //     let rowObjbottom = {};
    //     rowObjtop = tab[32];
    //     rowObjbottom = tab[40];
    //     let rowObj = {};
    //     // let flag = true;
    //     // debugger
    //     if(indice === 2){
    //        let finalVal = 0.0;
    //         for(let j = 32; j <= 40; j++){
    //             rowObj = tab[j];    
    //             finalVal = parseFloat(rowObj["Estimated Rs"]) + parseFloat(finalVal);
    //             if(j === 39){
    //                 finalVal = parseFloat(finalVal) - parseFloat(rowObj["Estimated Rs"]);
    //                 // rowObjbottom["Estimated Rs"] = parseFloat(finalVal);
    //                 rowObjtop["Projected Rs" + indice] = parseFloat(finalVal.toFixed(2));
    //                 rowObjbottom["Estimated Rs"] = parseFloat(finalVal.toFixed(2));
    //                 // break;
    //             }
    //        } 
    //     }else if(indice > 2){
    //         let finalVal = 0.0;
    //         for(let j = 32; j < 40; j++){
    //             rowObj = tab[j];
    //             finalVal = parseFloat(rowObj["Projected Rs" + (indice - 1)]) + parseFloat(finalVal);
    //             if(j === 39){
    //                 finalVal = parseFloat(finalVal) - parseFloat(values[indice - 1]);
    //                 rowObjtop["Projected Rs" + indice] = parseFloat(finalVal.toFixed(2));
    //                 rowObjbottom["Projected Rs" + (indice - 1)] = parseFloat(finalVal.toFixed(2));
    //                 // if(rowObjbottom["Projected Rs" + indice] !== 0){
    //                 //     flag = false;
    //                 // }
    //             }
    //             // else if(j === 40 && flag){
    //             //     rowObjbottom["Projected Rs" + (indice - 1)] = parseFloat(finalVal);
    //             // }
    //         }
    //     }
    //     if(indice === (projectedAmount.length - 1)){
    //         let finalVal = 0.0;
    //         for(let j = 32; j <= 40; j++){
    //             rowObj = tab[j];
    //             if(j !== 40){
    //                 finalVal = parseFloat(rowObj["Projected Rs" + (indice)]) + parseFloat(finalVal);
    //             }
    //             if(j === 39){
    //                 finalVal = parseFloat(finalVal) - parseFloat(values[indice]);
    //                 rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal.toFixed(2));
    //                 // rowObjtop["Projected Rs" + indice] = parseFloat(finalVal);
    //                 // if(rowObjbottom["Projected Rs" + indice] !== 0){
    //                 //     flag = false;
    //                 // }
    //             }
    //             // else if(j === 40 && flag){
    //             //     rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal);
    //             // }
    //         }
    //         // let drawings = [];
    //         let ownCapital = [];
    //         // for(let i = 0; i < projectedAmount.length; i++){
    //         //     if(i === 0){
    //         //         drawings.push("Drawings");
    //         //     }else if(i === 1){
    //         //         drawings.push(0);
    //         //     }else{
    //         //         drawings.push(values[i]);
    //         //     }
    //         // }
    //         for(let i = 0; i < projectedAmount.length; i++){
    //             if(i === 0){
    //                 ownCapital.push("OwnCapital");
    //             }else if(i === 1){
    //                 let rowObj = tab[33];
    //                 ownCapital.push(rowObj["Estimated Rs"]);
    //             }else if( i >= 2){
    //                 let rowObj = tab[32];
    //                 ownCapital.push(rowObj["Projected Rs" + i]);
    //             }
    //         }
    //         console.log(JSON.stringify(drawings));
    //         // setDrawings(JSON.stringify(drawings));
    //         setOwnCapital(JSON.stringify(ownCapital));
    //     }
    //     // // for(let k = 1; k <= indice; k++){
    //     //     let finalVal = 0.0;
    //     //     for(let j = 32; j <= 40; ){
    //     //         if(!flag){
    //     //             rowObj = tab[j];
    //     //             flag = true;
    //     //         }
    //     //         if(k === 1){
    //     //             finalVal = parseFloat(rowObj["Estimated Rs"]) + parseFloat(finalVal);   
    //     //             if(j === 39){
    //     //                 finalVal = parseFloat(finalVal) - parseFloat(rowObj["Estimated Rs"]);
    //     //             }
    //     //             if(j === 40){
    //     //                 rowObj["Estimated Rs"] = parseFloat(finalVal);
    //     //                 rowObj = tab[32];
    //     //                 rowObj["Projected Rs" + k + 1] = parseFloat(finalVal);
    //     //             }
    //     //         }else if(k > 1){
    //     //             finalVal = parseFloat(rowObj["Projected Rs" + k]) + parseFloat(finalVal);   
    //     //             if(j === 39){
    //     //                 finalVal = parseFloat(rowObj["Projected Rs" + k]) - parseFloat(finalVal);
    //     //             }
    //     //             if(j === 40){
    //     //                 rowObj["Projected Rs" + k] = parseFloat(finalVal);
    //     //                 rowObj = tab[32];
    //     //                 rowObj["Projected Rs" + k + 1] = parseFloat(finalVal);
    //     //             } 
    //     //         }
    //     //         if(flag && j <= 40){
    //     //             j++;
    //     //             // rowObj = tab[j];
    //     //             rowObj["Projected Rs" + k] = finalVal;
    //     //         }
    //     //     }
    //     // }
    //     SetTabData(tab);
    //     SetFlagRefresh(false);
    // }
    
    // getRepaymentScheduleDetails();

    const setNetworth = () => {
       try {
        if(Borrower.BorrowerId !== -1){
            let tab = [...tabData];
            let rowObjtop = {};
            let rowObjbottom = {};
            rowObjtop = tab[32];
            rowObjbottom = tab[40];
            let rowObj = {};
            for(let indice = 1; indice <= projectedAmount.length - 1; indice++){ 
    // let flag = true;
    // debugger
    if(indice === 1){
        let finalVal = 0.0;
        for(let j = 32 ; j < 40; j++){
            rowObj = tab[j];
            if(j === 39){
                finalVal = parseFloat(finalVal) - parseFloat(rowObj["Estimated Rs"])
                rowObjbottom["Estimated Rs"] = parseFloat(finalVal.toFixed(2));
                rowObjtop["Projected Rs" + (indice + 1)] = parseFloat(finalVal.toFixed(2));
            }else{
                finalVal = parseFloat(rowObj["Estimated Rs"]) + parseFloat(finalVal);
            }
        }
    }
    if(indice === 2){
       let finalVal = 0.0;
        for(let j = 32; j < 40; j++){
            rowObj = tab[j];    
            if(j === 39){
                finalVal = parseFloat(finalVal) - parseFloat(rowObj["Projected Rs" + (indice)]);
                // rowObjbottom["Estimated Rs"] = parseFloat(finalVal);
                rowObjtop["Projected Rs" + (indice + 1)] = parseFloat(finalVal.toFixed(2));
                rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal.toFixed(2));
                // break;
            }else{
                finalVal = parseFloat(rowObj["Projected Rs" + (indice)]) + parseFloat(finalVal);
            }
       } 
    }else if(indice > 2){
        let finalVal = 0.0;
        for(let j = 32; j < 40; j++){
            rowObj = tab[j];
            if(j === 39){
                finalVal = parseFloat(finalVal) - parseFloat(rowObj["Projected Rs" + (indice - 1)]);
                rowObjtop["Projected Rs" + indice] = parseFloat(finalVal.toFixed(2));
                rowObjbottom["Projected Rs" + (indice - 1)] = parseFloat(finalVal.toFixed(2));
                // if(rowObjbottom["Projected Rs" + indice] !== 0){
                //     flag = false;
                // }
            }else{
                finalVal = parseFloat(rowObj["Projected Rs" + (indice - 1)]) + parseFloat(finalVal);
            }
            // else if(j === 40 && flag){
            //     rowObjbottom["Projected Rs" + (indice - 1)] = parseFloat(finalVal);
            // }
        }
    }
    if(indice === (projectedAmount.length - 1)){
        let finalVal = 0.0;
        for(let j = 32; j < 40; j++){
            rowObj = tab[j];
            if(j === 39){
                finalVal = parseFloat(finalVal) - parseFloat(rowObj["Projected Rs" + (indice)]);
                rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal.toFixed(2));
                // rowObjtop["Projected Rs" + indice] = parseFloat(finalVal);
                // if(rowObjbottom["Projected Rs" + indice] !== 0){
                //     flag = false;
                // }
            }else{
                finalVal = parseFloat(rowObj["Projected Rs" + (indice)]) + parseFloat(finalVal);
            }
            // else if(j === 40 && flag){
            //     rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal);
            // }
        }
        // let drawings = [];
        let ownCapital = [];
        // for(let i = 0; i < projectedAmount.length; i++){
        //     if(i === 0){
        //         drawings.push("Drawings");
        //     }else if(i === 1){
        //         drawings.push(0);
        //     }else{
        //         drawings.push(values[i]);
        //     }
        // }
        for(let i = 0; i < projectedAmount.length; i++){
            if(i === 0){
                ownCapital.push("OwnCapital");
            }else if(i === 1){
                let rowObj = tab[33];
                ownCapital.push(rowObj["Estimated Rs"]);
            }else if( i >= 2){
                let rowObj = tab[32];
                ownCapital.push(rowObj["Projected Rs" + i]);
            }
        }
        console.log(JSON.stringify(drawings));
        // setDrawings(JSON.stringify(drawings));
        setOwnCapital(JSON.stringify(ownCapital));
    }
            }
        }else{
            SetIsShowSelectBorrowerMessage(true);
        }
       } catch (error) {
        alert(error);
       }
    }

    const refreshTotal = () => {
        try {
            if(Borrower.BorrowerId !== -1){
                let tab = [...tabData];
                let rowObjA = {};
                let rowObjB = {};
                let total = {};
                
                if(!flagRefresh){
                for(let j = 0; j < balanceSheetRow.length ; j++){
                    // rowObj = tab[j];
                    // debugger
                    if(j === 2){
                        rowObjA = tab[j];
                        rowObjB = tab[++j];
                        total = tab[4];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]); //+ parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                    }else if(j === 6){
                        for(let l = 6; l < 12; l++){
                            rowObjA = tab[l];
                            rowObjB = tab[++l];
                            total = tab[13];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]); //+ parseFloat(total["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }
                        }
                        j = 13;
                    }else if(j === 15){
                        rowObjA = tab[4];
                        rowObjB = tab[13];
                        total = tab[20];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]); //+ parseFloat(total["Estimated Rs"]) ;
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]); //+ parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        for(let l = 15; l < 19; l++){
                            rowObjA = tab[l];
                            rowObjB = tab[++l];
                            // total = tab[13];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }
                        }
                        j = 21;
                    }else if(j === 22){
                        // debugger
                        for(let l = 22; l < 28; l++){
                            rowObjA = tab[l];
                            rowObjB = tab[++l];
                            total = tab[30];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }
                        }
                        j = 30;
                    }else if(j === 40){
                        // debugger
                        rowObjA = tab[20];
                        rowObjB = tab[30];
                        total = tab[41];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[40];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        j = 43;
                    }else if(j === 44){
                        rowObjA = tab[44];
                        rowObjB = tab[45];
                        total = tab[46];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        j = 47;
                    }else if(j === 48){
                        for(let l = 48; l < 52; l++){
                            rowObjA = tab[l];
                            rowObjB = tab[++l];
                            total = tab[53];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }   
                        }
                        j = 53;
                    }else if(j === 54){
                        for(let l = 54; l < 61; l++){
                            rowObjA = tab[l];
                            rowObjB = tab[++l];
                            total = tab[62];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }
                        }
                        j = 62;
                    }else if(j === 64){
                        rowObjA = tab[46];
                        rowObjB = tab[53];
                        total = tab[66];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[62];
                        rowObjB = tab[63];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        // debugger
                        rowObjA = tab[64];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(closingCash !== null && closingCash.length !== 0){
                                if(k === 1){
                                    rowObjA["Estimated Rs"] = parseFloat(closingCash[k].toFixed(2));
                                }else{
                                    rowObjA["Projected Rs" + k] = parseFloat(closingCash[k].toFixed(2));
                                }
                            }
                        }
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        j = 67;
                    }else if(j === 68){
                        let rowObjA = tab[68];
                        let rowObjB = tab[69];
                        let totalA = tab[70];
                        let rowObjC = tab[70];
                        let rowObjD = tab[71];
                        let totalC = tab[72];
                        let rowObjE = tab[72];
                        let rowObjF = tab[73];
                        let totalE = tab[74];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                totalA["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                totalA["Estimated Rs"] = parseFloat(totalA["Estimated Rs"].toFixed(2));
                                totalC["Estimated Rs"] = parseFloat(rowObjC["Estimated Rs"]) - parseFloat(rowObjD["Estimated Rs"]);
                                totalC["Estimated Rs"] = parseFloat(totalC["Estimated Rs"].toFixed(2));
                                totalE["Estimated Rs"] = parseFloat(rowObjE["Estimated Rs"]) + parseFloat(rowObjF["Estimated Rs"]);
                                totalE["Estimated Rs"] = parseFloat(totalE["Estimated Rs"].toFixed(2));
                                rowObjA["Projected Rs" + (k + 1)] = parseFloat(totalE["Estimated Rs"].toFixed(2));
                            }else{
                                totalA["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                totalA["Projected Rs" + k] = parseFloat(totalA["Projected Rs" + k].toFixed(2));
                                totalC["Projected Rs" + k] = parseFloat(rowObjC["Projected Rs" + k]) - parseFloat(rowObjD["Projected Rs" + k]);
                                totalC["Projected Rs" + k] = parseFloat(totalC["Projected Rs" + k].toFixed(2));
                                totalE["Projected Rs" + k] = parseFloat(rowObjE["Projected Rs" + k]) + parseFloat(rowObjF["Projected Rs" + k]);
                                totalE["Projected Rs" + k] = parseFloat(totalE["Projected Rs" + k].toFixed(2));
                                rowObjA["Projected Rs" + (k + 1)] = parseFloat(totalE["Projected Rs" + k].toFixed(2));
                            }
                            // if(k === 1){
                            //     totalC["Estimated Rs"] = parseFloat(rowObjC["Estimated Rs"]) + parseFloat(rowObjD["Estimated Rs"]);
                            //     totalC["Estimated Rs"] = parseFloat(totalC["Estimated Rs"].toFixed(2));
                            // }else{
                            //     totalC["Projected Rs" + k] = parseFloat(rowObjC["Projected Rs" + k]) + parseFloat(rowObjD["Projected Rs" + k]);
                            //     totalC["Projected Rs" + k] = parseFloat(totalC["Projected Rs" + k].toFixed(2));
                            // }
                            // if(k === 1){
                            //     totalE["Estimated Rs"] = parseFloat(rowObjE["Estimated Rs"]) + parseFloat(rowObjF["Estimated Rs"]);
                            //     totalE["Estimated Rs"] = parseFloat(totalE["Estimated Rs"].toFixed(2));
                            // }else{
                            //     totalE["Projected Rs" + k] = parseFloat(rowObjE["Projected Rs" + k]) + parseFloat(rowObjF["Projected Rs" + k]);
                            //     totalE["Projected Rs" + k] = parseFloat(totalE["Projected Rs" + k].toFixed(2));
                            // }
                        }
                    }else if(j === 79){
                        for(let l = 79; l <= 81; l++){
                            let rowObjA = tab[l];
                            let rowObjB = tab[++l];
                            let total = tab[83];
                            for(let k = 1; k < projectedAmount.length; k++){
                                if(k === 1){
                                    total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                    total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                                }else{
                                    total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                    total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                                }
                            }
                        }
                        j = 84;
                    }else if(j === 85){
                        let rowObjA = tab[66];
                        let rowObjB = tab[74];
                        let total = tab[85];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[75];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[83];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[84];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(total["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(total["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[40];
                        rowObjB = tab[83];
                        total = tab[86];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) - parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) - parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[20];
                        rowObjB = tab[30];
                        total = tab[87];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) + parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) + parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        rowObjA = tab[41];
                        rowObjB = tab[85];
                        total = tab[88];
                        for(let k = 1; k < projectedAmount.length; k++){
                            if(k === 1){
                                total["Estimated Rs"] = parseFloat(rowObjA["Estimated Rs"]) - parseFloat(rowObjB["Estimated Rs"]);
                                total["Estimated Rs"] = parseFloat(total["Estimated Rs"].toFixed(2));
                            }else{
                                total["Projected Rs" + k] = parseFloat(rowObjA["Projected Rs" + k]) - parseFloat(rowObjB["Projected Rs" + k]);
                                total["Projected Rs" + k] = parseFloat(total["Projected Rs" + k].toFixed(2));
                            }
                        }
                        j = 88;
                    }
                }
                SetTabData(tab);
        
               /* let values = [...val];
                // values[indice] = parseFloat(e.target.value);
                // setVal(values);
                console.log(val);
                let tab = [...tabData];
                let rowObjtop = {};
                let rowObjbottom = {};
                rowObjtop = tab[32];
                rowObjbottom = tab[40];
                let rowObj = {};
                let flag = true;
                for(let indice = 2; indice < projectedAmount.length; indice++){
                    if(indice === 2){
                        let finalVal = 0.0;
                         for(let j = 32; j <= 40; j++){
                             rowObj = tab[j];    
                             finalVal = parseFloat(rowObj["Estimated Rs"]) + parseFloat(finalVal);
                             if(j === 39){
                                 finalVal = parseFloat(finalVal) - parseFloat(rowObj["Estimated Rs"]);
                                 // rowObjbottom["Estimated Rs"] = parseFloat(finalVal);
                                 rowObjtop["Projected Rs" + indice] = parseFloat(finalVal.toFixed(2));
                                 if(rowObjbottom["Estimated Rs"] !== 0){
                                     flag = false;
                                 }
             
                                 // break;
                             }else if(j === 40 && flag){
                                 rowObjbottom["Estimated Rs"] = parseFloat(finalVal.toFixed(2));
                                 // break;
                             }
                        } 
                     }else 
                     if(indice > 2){
                         let finalVal = 0.0;
                         for(let j = 32; j < 40; j++){
                             rowObj = tab[j];
                             finalVal = parseFloat(rowObj["Projected Rs" + (indice - 1)]) + parseFloat(finalVal);
                             if(j === 39){
                                 finalVal = parseFloat(finalVal) - parseFloat(values[indice - 1]);
                                 rowObjtop["Projected Rs" + indice] = parseFloat(finalVal.toFixed(2));
                                 rowObjbottom["Projected Rs" + (indice - 1)] = parseFloat(finalVal.toFixed(2));
                             }
                         }
                     }
                     if(indice === (projectedAmount.length - 1)){
                         let finalVal = 0.0;
                         for(let j = 32; j <= 40; j++){
                             rowObj = tab[j];
                             if(j !== 40){
                                 finalVal = parseFloat(rowObj["Projected Rs" + (indice)]) + parseFloat(finalVal);
                             }
                             if(j === 39){
                                 finalVal = parseFloat(finalVal) - parseFloat(values[indice]);
                                 rowObjbottom["Projected Rs" + (indice)] = parseFloat(finalVal.toFixed(2));
                             }
                         }
                     }
                     SetTabData(tab);
                }*/
               SetFlagRefresh(true);
            }
        }
        } catch (error) {
            alert(error);
        }
}
    
    // const handlePrint = () => {
    //     // Capture the content you want to print
    //     // const content = contentRef.current;
    
    //     // Open print dialog
    //     window.print();
    //   };

      const hideButtons = () => {
        SetIsShowButton(!buttonFlag);
        SetButtonFlag(!buttonFlag);
      }

    return (
        <>
        {IsShowButton && <>
        <button onClick={periodOfLoanf}>1. GetPeriodOfLoan</button>
        <button onClick={getOperatingStatementDetails}>2. GetOperatingStatementDetails</button>
        <button onClick={getDepreciationAllowable}>3. GetDepreciationAccumulated</button>
        <button onClick={getCashFlowDetails}>4. GetCashFlowDetail</button>
        <button onClick={assetsDetail}>5. GetAssetsDetail</button>
        {isRefreshButton &&  <div>
        <button onClick={settableData}>6. GetBalanceSheet</button>
        <button onClick={setNetworth}>7. SetNetWorth</button>
        <button onClick={refreshTotal}>8. Refresh</button> </div>}
        <button onClick={saveBalanceSheetDetails}>9. SaveDetails</button>
        <button onClick={generatePDF}>10. DownloadAsPdf</button></>}

        <button onClick={hideButtons}>...</button>
        <div ref={contentRef} style={{padding: '20px'}}>
            <h2 style={{textAlign:"center"}}>{borrowerProfile.borrower_sFirmName}</h2>
            <h3 style={{textAlign:"center"}}>Borrower:&nbsp; {borrowerProfile.borrowerName}</h3>
            <h3 style={{textAlign:"center"}}>Address of unit:  {borrowerProfile.borrowerRegisteredAddress}</h3>
            <h4 style={{textAlign:"center"}}><u>BalanceSheet</u></h4>
            <table>
                <thead>
                    {projectedAmount.map((element, _index) => (
                        // <tr key={index}>
                            <td  style={{ border: '1px solid black', width: '100px' }}>{element} </td>
                        // </tr>
                    ))}
                </thead>
                <tbody>
                    {/* {balanceSheetRow.map((element, index) => (
                    <tr key={index}>
                        <th style={{ border: '1px solid black', width: "100px", backgroundColor: 'greenyellow'}}>{element} </th>
                    </tr>))} */}
                    {
                        tabData.map((element, index) => <tr key={index}>
                            {
                                newProjectedAmount.map((column, indice) => 
                                <th style={{border: '1px solid black', width: "100px"}}>
                                    {/* { ((index === 39 && indice > 1 )) ? <input
                                style={{ width: '100px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number"
            value={val[indice]} // Bind the input value to the state
            onChange={(e) => handleChange(e, indice)} // Handle input changes
          />: */}
                                        {element[column]}
                                    {/* } */}
                                </th>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
            <h5 style={{textAlign: "right"}}>FOR {borrowerProfile.borrower_sFirmName}</h5>
            <br/>
            <h5 style={{textAlign: "right"}}>{borrowerProfile.borrowerName}</h5>
            <h5 style={{textAlign: "right"}}>Borrower</h5>
        </div>
        {isShowDenialMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDenialMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                            Project Data is Missing!..
                            </h2>

                            <center><button onClick={closeDenialMessageModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

        {isShowSelectBorrowerMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
            {isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
        </>
    )

}


export default BalanceSheet;