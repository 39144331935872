import { useState } from "react";
import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

function Ratios(Borrower){
    
    const [periodOfLoan, setPeriodOfLoan] = useState(0);
    const [projectedAmount, setProjectedAmount] = useState([]);
    const [repaymentVariables, setRepaymentVariables] = useState({});
    const [periodAndMonthArr, setPeriodAndMonthArr] = useState([]);
    const [tabData, SetTabData] = useState([]);
    const [newProjectedAmount, setNewProjectedAmount] = useState([]);
    const [netSales, SetNetSales] = useState([]);
    const [operatingProfitBeforeTax, SetOperatingProfitBeforeTax] = useState([]);
    const [operatingProfitAfterTax, SetOperatingProfitAfterTax] = useState([]);
    const [ownCapital, SetOwnCapital] = useState([]);
    const [depreciationAllowable, SetDepreciationAllowable] = useState([]);
    const [provisionForTax, SetProvisionForTax] = useState([]);
    const [operatingProfitBeforeDITax, SetOperatingProfitBeforeDITax] = useState([]);
    const [isShowSelectBorrowerMessage, SetIsShowSelectBorrowerMessage] = useState(false);
    const [isShowDenialMessage, SetIsShowDenialMessage] = useState(false);
    const [isShowRatiosButton, SetIsShowRatiosButton] = useState(false);
    const [buttonFlag, SetButtonFlag] = useState(false);
    const [IsShowButton, SetIsShowButton] = useState(true);

    let ratiosRow = ["1) Operating Profit Margin on Profitability statement basis", "", "2) Return on Capital Employed (ROCE)(WN2)", "", "3) Interest coverage ratio(WN3)",
        "", "4) Principal coverage ratio(WN4)", "", "5) Net profit ratio", "6) Debt Service coverage Ratio", ""
    ];

    let borrowerDetailProjectItem = {};
borrowerDetailProjectItem.BorrowerId = Borrower.BorrowerId;
const contentRef = useRef();
const generatePDF = () => {
  const input = contentRef.current; // Capture the DOM element

  const options = {
    margin: 0.2,
    filename: 'Ratios.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
};

html2pdf().set(options).from(input).save();

  // Convert the content to canvas using html2canvas
  // html2canvas(input, {scale: 2}).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('landscape', 'mm', 'a4');
  //   const imgWidth = 210; // Width of the A4 page in mm
  //   const pageHeight = 297; // Height of A4 page in mm
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   // Add the image to the PDF
  //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;

  //   // If the content exceeds one page, add additional pages
  //   while (heightLeft > 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }

  //   // Save the PDF file
  //   pdf.save('Ratios.pdf');
  // });
};
    async function periodOfLoanf() {
      try {
        if(Borrower.BorrowerId !== -1){
          const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getPeriodOfLoan', {
              method: "POST",
              mode: "cors",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(borrowerDetailProjectItem)
          });
      
          if (response.ok) {
              const data = await response.json();
              console.log(data);
              if (data.Message === "Success") {
                  setPeriodOfLoan(data.PeriodOfLoan);
                  console.log(data.PeriodOfLoan);
                  let UpdateProjectedAmount = ["Particulars", "Estimated"];
                  for (let i = 0; i < data.PeriodOfLoan; i++) {
                      UpdateProjectedAmount.push("Projected");
                  }
                  setProjectedAmount(UpdateProjectedAmount);
                  // for(let i = 0; i < data.PeriodOfLoan; i++){
                  //     periods.push(i)
                  // }
                  
              } else {
                  setPeriodOfLoan(0);
              }
          } else {
              console.log("Failure");
          }
          console.log("PeriodOfLoan: "+ periodOfLoan);
  
          const responseRepaymentSchedule = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getEmiPeriod",{method:"POST",
              mode:"cors",
              headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(borrowerDetailProjectItem)
          }).then((response) =>
         { 
          console.log(response);
          if(response.ok){
            console.log("Success");
          }else{
            console.log("Failure");
          }
          return response.json();
         }
        ).then((data) => {
          console.log(data.body);
          if(data.Message === "Success"){
            console.log("Data: Success");
          }
          setRepaymentVariables(data.sourceOfFunds);
          setPeriodAndMonthArr(data.PeriodAndMonth);
          console.log(data);
          return data;
        });
          console.log(responseRepaymentSchedule);
      }else{
        SetIsShowSelectBorrowerMessage(true);
      }
      } catch (error) {
        alert(error);
      }
    }

    async function getOperatingStatementDetails(){
      try {
        if(Borrower.BorrowerId !== -1){
          let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getOperatingStatementDetail',{
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
          });
        
          if(response.ok){
              // debugger
            const data = await response.json();
            if(data.Message === "Success") {
              // let flag = true;
              let count = 0;
              let netSales = data.NetSales;
              if(netSales === '' || netSales === null){
                SetIsShowDenialMessage(true);
                // flag = false;
                SetIsShowRatiosButton(false);
              }else{
                SetNetSales(JSON.parse(netSales));
                count = count + 1;
              }
              let operatingProfitBeforeTax = data.OperatingProfitBeforeTax;
              if((operatingProfitBeforeTax === '' || operatingProfitBeforeTax === null)){
                SetIsShowDenialMessage(true);
                SetIsShowRatiosButton(false);
              }else{
                SetOperatingProfitBeforeTax(JSON.parse(operatingProfitBeforeTax));
                count = count + 1;
              }
              let operatingProfitAfterTax = data.OperatingProfitAfterTax;
              if((operatingProfitAfterTax === '' || operatingProfitAfterTax === null)){
                SetIsShowDenialMessage(true);
                SetIsShowRatiosButton(false);
              }else{
                SetOperatingProfitAfterTax(JSON.parse(operatingProfitAfterTax));
                count = count + 1;
              }
              let provisionForTax = data.ProvisionForTax
              if((provisionForTax === '' || provisionForTax === null)){
                SetIsShowDenialMessage(true);
                SetIsShowRatiosButton(false);
              }else{
                SetProvisionForTax(JSON.parse(provisionForTax));
                count = count + 1;
              }
              let operatingProfitBeforeDITax = data.OperatingProfitBeforeDITax;
              if((operatingProfitBeforeDITax === '' || operatingProfitBeforeDITax === null)){
                SetIsShowDenialMessage(true);
                SetIsShowRatiosButton(false);
              }else{
                SetOperatingProfitBeforeDITax(JSON.parse(operatingProfitBeforeDITax));
                count = count + 1;
              }
              if(count === 5){
                SetIsShowDenialMessage(false);
                SetIsShowRatiosButton(true);
              }
            }else if(data.Message === "Failure") {
              SetIsShowDenialMessage(true);
            }
          }
        }else{
          SetIsShowSelectBorrowerMessage(true);
        }
      } catch (error) {
        alert(error);
      }
      }
    

      async function getBalanceSheetDetails(){
        try {
          if(Borrower.BorrowerId !== -1){
            let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch('https://lblpr.com/api/v1/creditMonitoringAnalysis/getBalanceSheetDetail',{
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
          });
        
          if(response.ok){
            const data = await response.json();
            if(data.Message === "Success") {
              let ownCapital = data.OwnCapital;
              if(ownCapital === '' || ownCapital === null){
                SetIsShowDenialMessage(true);
              }else{
                SetOwnCapital(JSON.parse(ownCapital));
              }
              console.log(ownCapital);
            }else if(data.Message === "Failure") {
              SetIsShowDenialMessage(true);
            }
          }
          }else{
            SetIsShowSelectBorrowerMessage(true);
          }
        } catch (error) {
          alert(error);
        }
      }

      const getDepreciationAllowable = async () => {

        try {
          // console.log(Borrower.BorrowerId);
          if(Borrower.BorrowerId !== -1){
            let reqBody = {};
            reqBody.BorrowerId = Borrower.BorrowerId;
            const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getDepreciationValues",{method:"POST",
            mode:"cors",
            headers: {
              "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody)
        }).then
        ((response) =>
        { 
         console.log(response);
         if(response.ok){
           console.log("Success");
         }else{
           console.log("Failure");
         }
         return response.json();
        }
        ).then((data) => {
        console.log(data.Message, data);
        let depreciation = data.Depreciation;
        if(depreciation === '' || depreciation === null){
          SetIsShowDenialMessage(true);
          
        }
        else{
          depreciation = JSON.parse(depreciation);
          
        }  
        SetDepreciationAllowable(depreciation);
      //   console.log(depreciationAllowable);
        });
        console.log(response);
          }else{
            SetIsShowSelectBorrowerMessage(true);
          }
        } catch (error) {
          alert(error);
        }
    }

    function closeMessageModal(){
      SetIsShowSelectBorrowerMessage(false);
    }

  function closeDenialMessageModal(){
      SetIsShowDenialMessage(false);
    }

    // getRepaymentScheduleDetails();

    const settableData = () => {
        try {
          if(Borrower.BorrowerId !== -1){
            let tab = [];
        // getOperatingStatementDetails();
        let principalAmounts = periodAndMonthArr.map((element) => (element.PrincipalAmount));
        let outStandingValue = periodAndMonthArr.map((element) => (element.TotalOutstandingValue));
        let InterestValue = periodAndMonthArr.map((element) => (element.Interest.toFixed(2)));
        let TotalEMI = periodAndMonthArr.map((element) => (element.TotalEmi));
        console.log(TotalEMI);
        let projectAmount = [];
        for(let i = 0; i < projectedAmount.length; i++){
            if(i <= 1)
            projectAmount[i] = projectedAmount[i];
            else{
                projectAmount[i] = projectedAmount[i] + i;
            }
        }
        setNewProjectedAmount(projectAmount);
        console.log(projectAmount);
        console.log(projectedAmount);
        console.log(repaymentVariables);
       
        for(let i = 0; i < ratiosRow.length; i++){
           
            let rowObj = {};

            for(let k = 0; k < projectedAmount.length; k++){   // && j < principalAmounts.length
                // let tabA = [...tabData];
                let value = projectAmount[k];
                rowFinding(i, k);
                function rowFinding(i, k){
                    if(k > 0){
                        rowObj[value] = parseFloat(0);
                    }else if(k===0){
                        rowObj[value] = ratiosRow[i];
                    }if((i === 1 || i === 3 || i === 5 || i === 7 || i === 10) && k !== 0){  //|| i === 75
                        rowObj[value] = " ";
                    }if(i === 0 && k >= 1){
                        console.log(operatingProfitBeforeTax[k], netSales[k]);
                        rowObj[value] = parseFloat(parseFloat(operatingProfitBeforeTax[k]) / parseFloat(netSales[k]) * 100).toFixed(2);
                    }else if(i === 2 && k >= 1){
                        console.log(operatingProfitAfterTax[k], outStandingValue[k], ownCapital[k]);
                        rowObj[value] = (parseFloat(operatingProfitAfterTax[k]) / (parseFloat(outStandingValue[k]) + parseFloat(ownCapital[k])) * 100 ).toFixed(2);
                    }else if(i === 4 && k >= 1){
                      rowObj[value] = ((parseFloat(operatingProfitAfterTax[k]) + parseFloat(depreciationAllowable[k - 1]) + parseFloat(InterestValue[k - 1]) + parseFloat(provisionForTax[k])) / parseFloat(InterestValue[k - 1])).toFixed(2);
                    }else if(i === 6 && k >= 1){
                        rowObj[value] = ((parseFloat(operatingProfitAfterTax[k]) + parseFloat(depreciationAllowable[k - 1]) + parseFloat(provisionForTax[k])) / parseFloat(principalAmounts[k - 1])).toFixed(2);
                    }else if(i === 8 && k >= 1){
                        rowObj[value] = parseFloat(parseFloat(operatingProfitAfterTax[k]) / parseFloat(netSales[k]) * 100).toFixed(2);
                    }else if(i === 9 && k >= 1){
                      rowObj[value] = (parseFloat(operatingProfitBeforeDITax[k])/parseFloat(TotalEMI[k - 1])).toFixed(2);
                    }
                }
            }
            tab.push(rowObj);
        }
        SetTabData(tab);
          }else{
            SetIsShowSelectBorrowerMessage(true);
          }
        } catch (error) {
          alert(error);
        }
    };
    
    // const handlePrint = () => {
    //     // Capture the content you want to print
    //     // const content = contentRef.current;
    
    //     // Open print dialog
    //     window.print();
    //   };

      const hideButtons = () => {
        SetIsShowButton(!buttonFlag);
        SetButtonFlag(!buttonFlag);
      }

    return(
    <>
    {IsShowButton && <>
    <button onClick={periodOfLoanf}>1. GetPeriodOfLoan</button>
        <button onClick={getOperatingStatementDetails}>2. GetOperatingStatementDetails</button>
        <button onClick={getBalanceSheetDetails}>3. GetBalanceSheetDetails</button>
        <button onClick={getDepreciationAllowable}>4. GetDepreciationAllowable</button>
        {isShowRatiosButton && <button onClick={settableData}>5. GetRatios</button>}
        <button onClick={generatePDF}>6. DownloadAsPdf</button></>}
        <button onClick={hideButtons}>...</button>
        <div ref={contentRef}>
          <br/>
          <br/>
            <span>
                <center><b>Ratios</b></center>
            </span>

            <table>
                <thead>
                    {projectedAmount.map((element, index) => (
                        // <tr key={index}>
                            <td style={{ border: '1px solid black', width: '100px' }}>{element} </td>
                        // </tr>
                    ))}
                </thead>
                <tbody>
                    {
                    // ratiosRow.map((element, index) => (
                    // <tr key={index}>
                    //     <th style={{ border: '1px solid black', width: "100px", backgroundColor: 'greenyellow'}}>{element} </th>
                    // </tr>))
                    tabData.map((element, index) => <tr key={index}>
                            {
                                newProjectedAmount.map((column, _indice) => 
                                <th style={{ border: '1px solid black', width: '100px' }}>
                                    {
                                        element[column]
                                    }
                                </th>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
        </div>

        {isShowSelectBorrowerMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

        {isShowDenialMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDenialMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                            Project Data is Missing!..
                            </h2>

                            <center><button onClick={closeDenialMessageModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
    </>
    );
}

export default Ratios;