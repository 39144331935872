import { React, useState } from "react";
import { FcApproval } from "react-icons/fc";
import { ImCross } from "react-icons/im";

function Card( borrowerDetail ) {
    const [isClicked, setIsClicked] = useState(false);
    const [isShowCardDeleteMessage, setShowCardDeleteMessage] = useState(false);
    const [isShowEditModal, SetShowEditModal] = useState(false);
    const [isShowUserUpdateMessage, SetShowUserUpdateMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [userUpdateMessage, SetUserUpdateMessage] = useState('');

    const borrower = borrowerDetail.borrower;
    const prevKey = borrowerDetail.prevKey;

    const [borrowerName, SetBorrowerName] = useState(borrower.borrowerName);
    const [borrowerPan, SetBorrowerPan] = useState(borrower.borrowerPan);
    const [borrowerPurposeOfLoan, SetPurposeOfLoan] = useState(borrower.purposeOfLoan);
    const [borrowerRegAddress, SetRegisteredAddress] = useState(borrower.borrowerRegAddress);
    const [aboutBorrower, SetAboutBorrower] = useState(borrower.aboutBorrower);
    const [borrower_sFirmName, SetBorrower_sFirmName] = useState(borrower.borrower_sFirmName);

    console.log('borrower:', borrower.borrowerId);

    async function editUser() {
        // Implementation of editUser logic
        SetShowEditModal(true);
    }

    async function deleteUser() {
        const reqBody = {
            BorrowerId: borrower.borrowerId,
        };

        try {
            const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/deleteUser", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqBody),
            });

            const data = await response.json();
            console.log(data);

            setDeleteMessage(data.Message);
            setShowCardDeleteMessage(true);

            if (data.Message === "Deletion Success") {
                setIsClicked(false);
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            setDeleteMessage("Deletion Failed");
            setShowCardDeleteMessage(true);
        }
    }

    async function handleEditUserSave() {
      const reqObj = {};
    reqObj.borrowerName = borrowerName;
    reqObj.borrowerAddress = borrowerRegAddress;
    reqObj.borrowerPan = borrowerPan;
    reqObj.purposeOfLoan = borrowerPurposeOfLoan;
    reqObj.aboutBorrower = aboutBorrower;
    reqObj.borrower_sFirmName = borrower_sFirmName;
    console.log(reqObj);
      try {
        const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/editUserDetails",{
          method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqObj),
        });

        // debugger
        const data = await response.json();
            console.log(data);
            SetShowEditModal(false);
            SetShowUserUpdateMessage(true);
            SetUserUpdateMessage(data.Message);
      } catch (error) {
          console.error("Error editing user:", error);
      }
    }

    function closeModal() {
        setDeleteMessage('');
        setShowCardDeleteMessage(false);
    }

    function closeEditModal(){
      SetShowEditModal(false);
    }

    function closeUserUpdateModal(){
      SetUserUpdateMessage('');
      SetShowUserUpdateMessage(false);
    }

    function handleClickBorrower() {
        const prevCard = prevKey !== -1 ? document.getElementById('borrowerDetailCard' + prevKey) : null;
        setIsClicked(!isClicked);

        if (isClicked) {
            borrowerDetail.BorrowerId_prevKey(-1, -1);
        } else {
            if (prevCard) {
                prevCard.style.backgroundColor = 'White';
                prevCard.style.borderColor = 'Red';
            }
            borrowerDetail.BorrowerId_prevKey(borrower.borrowerId, borrowerDetail.Key);
        }
    }

    return (
        <>
            <div
                id={'borrowerDetailCard' + borrowerDetail.Key}
                style={{
                    border: "2px solid black",
                    padding: "5px",
                    margin: "10px",
                    textAlign: "center",
                    borderRadius: "5px",
                    borderColor: isClicked ? 'Black' : 'Red',
                    backgroundColor: isClicked ? 'lightGreen' : 'White',
                    width: "100%",
                }}
                onClick={handleClickBorrower}
            >
                <h2>{borrower.borrowerName}</h2>
                <span>{borrower.borrowerPan}</span>
                <p>{borrower.purposeOfLoan}</p>
            </div>
            <div>
                <button onClick={editUser}>Edit User</button>
                <button onClick={deleteUser}>Delete User</button>
            </div>
            {isShowCardDeleteMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        {deleteMessage === 'Deletion Success' ? (
                            <h2 style={{ color: "green" }}>
                                User Deleted Successfully!! Please Reload..
                                <br /><br /><br />
                                <center><FcApproval /></center><br/><br/>
                                <center><button onClick={closeModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                            </h2>
                        ) : (
                            <h2 style={{ color: "red" }}>
                                User Deletion Failed!!
                                <br /><br /><br /><br />
                                <center><ImCross /></center><br/><br/>
                                <center><button onClick={closeModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                            </h2>
                        )}
                    </div>
                </div>
            )}

            {isShowEditModal && (
        <div className="modal-overlay" style={{overflowY: 'scroll'}}>
          <div className="modal-content">
            <span className="close" onClick={closeEditModal}>&times;</span>
            <h2>Edit User Details</h2>
            <h4>Borrower Name</h4>
            <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={borrowerName}
            onChange={(e) => SetBorrowerName(e.target.value)}
          />
          <br />
          <br />

          <h4>Borrower Pan</h4>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={borrowerPan}
            onChange={(e) => SetBorrowerPan(e.target.value)}
          />

          <br/>
          <br/>

          <h4>Purpose Of Loan</h4>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={borrowerPurposeOfLoan}
            onChange={(e) => SetPurposeOfLoan(e.target.value)}
          />

          <br/>
          <br/>

          <h4>Borrower Registered Address</h4>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={borrowerRegAddress}
            onChange={(e) => SetRegisteredAddress(e.target.value)}
          />

          <br/>
          <br/>

          <h4>Borrower's Firm Name</h4>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={borrower_sFirmName}
            onChange={(e) => SetBorrower_sFirmName(e.target.value)}
          />

          <br/>
          <br/>

          <h4>Borrower Description</h4>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={aboutBorrower}
            onChange={(e) => SetAboutBorrower(e.target.value)}
          />

          <br/>
          <br/>

          <button onClick={handleEditUserSave}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={closeEditModal}>Cancel</button>
          </div>
        </div>)}


        {isShowUserUpdateMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeUserUpdateModal}>&times;</span>
                        {userUpdateMessage === 'Updation Success!' ? (
                            <h2 style={{ color: "green" }}>
                                User Updated Successfully!! Please Reload..
                                <br /><br /><br />
                                <center><FcApproval /></center>

                                <center><button onClick={closeUserUpdateModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                            </h2>
                        ) : (
                            <h2 style={{ color: "red" }}>
                                User Updation Failed!!
                                <br /><br /><br /><br />
                                <center><ImCross /></center>

                                <center><button onClick={closeUserUpdateModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                            </h2>
                        )}
                    </div>
                </div>
            )}

        </>
    );
}

export default Card;
