import React, { useState } from 'react';
import { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

// Icons as simple SVG components
const IconUser = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const IconMapPin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
    <circle cx="12" cy="10" r="3"></circle>
  </svg>
);

const IconCreditCard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
    <line x1="1" y1="10" x2="23" y2="10"></line>
  </svg>
);

const IconFileText = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
  </svg>
);

const IconInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

const BorrowerProfile = (borrower ) => {
  // const [showContact, setShowContact] = useState(false);
console.log(borrower);
  const [isShowMessage, SetIsShowMessage] = useState(false);
  const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);
  const [borrowerProfile, SetBorrowerProfile] = useState({});

  const contentRef = useRef();
  const generatePDF = () => {
    const input = contentRef.current; // Capture the DOM element

    // Convert the content to canvas using html2canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // Width of the A4 page in mm
      const pageHeight = 297; // Height of A4 page in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // If the content exceeds one page, add additional pages
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF file
      pdf.save('BorrowerProfile.pdf');
    });
  };


  const getUserProfile = async () => {
    try {
        if(borrower.BorrowerId !== -1){
            let reqObj = {};
            reqObj.BorrowerId = borrower.BorrowerId;

            const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getBorrowerProfile", {method: 'POST',
                mode: 'cors',
                headers:{
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqObj)
        }).then((response) => {
            // debugger
            if(response.ok)
            return response.json();
        }).then((data) => {
            if(data.Message === 'Success'){
                SetBorrowerProfile(data.Profile);
            }else if(data.Message === 'Failure'){
                SetIsShowNoDataMessage(true);
            }
        });

        console.log(response);
        }else{
            SetIsShowMessage(true);
        }
    } catch (error) {
        alert(error);
    }
  }

  function closeMessageModal(){
    SetIsShowMessage(false);
  }

  function closeNoDataModal(){
    SetIsShowNoDataMessage(false);
  }

  return (
    <>
    <button onClick={getUserProfile} style={styles.button}>...</button>
    <br/>
    <button onClick={generatePDF} style={styles.button}>DownloadAsPdf</button>
    <div  ref={contentRef} style={styles.container}>
      <div style={styles.profileLabel}><h3>PROFILE</h3></div>
      
      <div style={styles.header}>
        <div style={styles.avatar}>
          {borrowerProfile.avatar ? (
            <img src={borrowerProfile.avatar} alt={borrowerProfile.borrowerName} style={styles.avatarImage} />
          ) : (
            <div style={styles.avatarFallback}>
              <IconUser />
            </div>
          )}
        </div>
        <h1 style={styles.name}>{borrowerProfile.borrowerName}</h1>
        <div style={styles.badge}>{borrowerProfile.borrower_sFirmName}</div>
        {/* <button style={styles.button} onClick={() => setShowContact(!showContact)}>
          {showContact ? 'Hide Contact' : 'Show Contact'}
        </button> */}
      </div>
      
      {/* {showContact && (
        <div style={styles.contactCard}>
          <div style={styles.contactInfo}>
            <span>Email: {borrowerProfile.email}</span>
            <span>Phone: {borrowerProfile.phone}</span>
            {/* <a href={borrowerProfile.website} target="_blank" rel="noopener noreferrer" style={styles.link}>
              Website
            </a> 
          </div>
        </div>
      )}
       */}
      {isShowMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

{isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
      
      <div style={styles.grid}>
        <Card title="Registered Address" icon={<IconMapPin />}>
          <p>{borrowerProfile.borrowerRegisteredAddress}</p>
        </Card>
        <Card title="PAN" icon={<IconCreditCard />}>
          <p>{borrowerProfile.borrowerPan}</p>
        </Card>
        <Card title="About the Project" icon={<IconFileText />}>
          <p>{borrowerProfile.purpose_of_Loan}</p>
        </Card>
        <Card title="About the Borrower" icon={<IconInfo />}>
          <p>{borrowerProfile.aboutBorrower}</p>
        </Card>
      </div>
    </div>
    </>
  );
};

const Card = ({ title, icon, children }) => (
  <div style={styles.card}>
    <div style={styles.cardHeader}>
      <span style={styles.cardIcon}>{icon}</span>
      <h2 style={styles.cardTitle}>{title}</h2>
    </div>
    <div style={styles.cardContent}>
      {children}
    </div>
  </div>
);

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '24px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  profileLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    backgroundColor: '#3498db',
    color: 'white',
    padding: '4px 12px',
    borderRadius: '16px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
    marginTop: '32px',
  },
  avatar: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginBottom: '16px',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  avatarFallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    color: '#333',
  },
  name: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '8px',
    textAlign: 'center',
  },
  badge: {
    backgroundColor: '#e0e0e0',
    color: '#333',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    marginBottom: '16px',
  },
  button: {
    backgroundColor: '#3498db',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  contactCard: {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  contactInfo: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  link: {
    color: '#3498db',
    textDecoration: 'none',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '16px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  cardIcon: {
    marginRight: '8px',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
  },
  cardContent: {
    fontSize: '14px',
    color: '#555',
  },
};

// Sample data for demonstration
// const sampleBorrower = {
//   name: "John Doe",
//   firmName: "Doe Enterprises",
//   address: "123 Business Street, Cityville, State 12345",
//   pan: "ABCDE1234F",
//   projectDescription: "An innovative green energy solution for urban areas, focusing on sustainable development and reducing carbon footprint.",
//   about: "John Doe is a seasoned entrepreneur with over 15 years of experience in the renewable energy sector. He has successfully led multiple projects and is known for his innovative approach to sustainable development.",
//   avatar: "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg", // Empty string to demonstrate the fallback icon
//   email: "john.doe@doeenterprises.com",
//   phone: "+1 (555) 123-4567",
//   website: "https://www.doeenterprises.com"
// };

// export default function BorrowerProfilePage() {
//   return <BorrowerProfile borrower={sampleBorrower} />;
// }
export default BorrowerProfile;