import React, { useState, useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { useRef } from 'react';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
import './Modal.css';
import './HoverText.css';
import { FcApproval } from "react-icons/fc";
import { ImCross } from "react-icons/im";
import { SlPencil, SlTrash } from "react-icons/sl";

function CostOfProject( BorrowerDetail) {
  let [isAddItem, setIsAddItem] = useState(false);
  const [aboutEditProject, setAboutEditProject] = useState('');
  const [amountOfEditProject, setAmountOfEditProject] = useState(0.0);
  const [gstEditValue, SetGstEditValue] = useState(0.0);
  const [projectIndex, setProjectIndex] = useState(-1);
  const [aboutProject, setAboutProject] = useState('');
  const [amountOfProject, setAmountOfProject] = useState(0.0);
  const [isShowMessage, SetIsShowMessage] = useState(false);


  const [periodOfLoan, setPeriodOfLoan] = useState(0);
  const [noOfInstallments, setNoOfInstallments] = useState(0);
  const [ContributionAsMarginMoney, setContributionAsMarginMoney] = useState(0.0);

  const [marginMoneyPercent, setMarginMoneyPercent] = useState(0.0);
  const [loanPercentAmount, setLoanPercentAmount] = useState(0.0);
  // const [marginMoney, setMarginMoney] = useState(0.0);
  const [loanAmount, setLoanAmount] = useState(0.0);
  const [interestRateByBank, setInterestRateByBank] = useState(0.0);

  // const [isProjects, setIsProjects] = useState(false);
  // const [filterProjects, setFilterProjects] = useState([]);
  const [totalCostOfProject, setTotalCostOfProject] = useState(0.0);
  const [totalGSTValue, SetTotalGstValue] = useState(0.0);
  const [moratoriumValue, SetMoratoriumValue] = useState(1);
  // const [deletedId, setDeletedId] = useState([]);

  const [emiStartMonth, SetEmiStartMonth] = useState('');
  const [emiStartYear, SetEmiStartYear] = useState((new Date().getFullYear()).toString());
  const [gstValue, SetGstValue] = useState(0);
  const [condVal, SetCondVal] = useState('Yes');
  const [isItemEdit, setIsItemEdit] = useState(false);
  const [isShowProject, setIsShowProject] = useState(true);
  // const [isShowGst, SetIsShowGst] = useState(true);
  // const [isShowGstPer, SetIsShowGstPer] = useState(false);
  const [isProjectsSaved, setIsProjectsSaved] = useState(false);
  const [isProjectItemsSavedSuccess, setIsProjectItemsSavedSuccess] = useState(false);
  const [isShowEditNdelete, SetIsShowEditNdelete] = useState(true);
  const [isShowEnterItem, SetIsShowEnterItem] = useState(true);
  const [isShowStartPoints, SetIsShowStartPoints] = useState(true);
  const [isShowSourceOfFund, SetIsShowSourceOfFund] = useState(true);
  const [isShowHiddingButton, SetIsShowHiddingButton] = useState(false);
  const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);
  const [sourcesOfFunds, SetSourcesOfFunds] = useState({});
  const [emiPeriodDetails, SetEmiPeriodDetails] = useState({});
  // const [isShowSourcesOfFund, SetIsShowSourcesOfFund] = useState(false);
  const [flag, setFlag] = useState(false);

  let [deletedProjects, setDeletedProjects] = useState([]);

  let borrowerDetailProjectItem = {};
  borrowerDetailProjectItem.BorrowerId = BorrowerDetail.BorrowerId;

  const [projects, setProjects] = useState([]);
  const [borrowerProfile, SetBorrowerProfile] = useState({});
  const [isRefreshed, setIsRefreshed] = useState(false);
  const contentRef = useRef(null);
  const generatePDF = () => {
    const input = contentRef.current; // Capture the DOM element

  const options = {
    margin: 0.2,
    filename: 'CostOfTheProject.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
};

html2pdf().set(options).from(input).save();
  //   // Convert the content to canvas using html2canvas
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210; // Width of the A4 page in mm
  //     const pageHeight = 297; // Height of A4 page in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     // Add the image to the PDF
  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     // If the content exceeds one page, add additional pages
  //     while (heightLeft > 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     // Save the PDF file
  //     pdf.save('CostOfTheProject.pdf');
  //   });
  };

  async function enterProduct(){
    if(!isRefreshed){
      // enterProduct();
    if(borrowerId !== -1){
      SetIsShowHiddingButton(true);
        setIsAddItem(true);
        const response = await fetch ('https://lblpr.com/api/v1/creditMonitoringAnalysis/getProjectItems',{
          method: "POST",
          mode:"cors",
        headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(borrowerDetailProjectItem)
        }).then((response) => { return response.json();}).then((data) => {
               
          console.log(data.Message);
          //projects = [...data.ProjectItemsList];
          // if(projects === undefined){
          //   setProjects([...data.ProjectItemsList]);
          // }else{
          //   setProjects([...projects, data.ProjectItemsList]);
          // }
           
          //  if(data.ProjectItemsList.length === 0){
          //   setIsShowProject(true);
          //  }
          console.log(data.ProjectItemsList);
          console.log(projects);

          // if(projects === undefined){
          //   setIsShowProject(false);
          // }else if(projects.length > 0){
          //   setIsShowProject(true);
          // }
          // debugger
          if (data.ProjectItemsList && data.ProjectItemsList.length > 0) {
            setProjects(prevProjects => prevProjects ? [...prevProjects, ...data.ProjectItemsList] : [...data.ProjectItemsList]);
            setIsShowProject(true);
        } else if(projects && projects.length > 0){
            setIsShowProject(true);
        }
        // else{
        //     setIsShowProject(false);
        // }

        })
        console.log(response);
        // let totalCost = 0.0;
        const totalCost = projects.reduce((element, curr) => parseFloat(curr.amountOfProject) + parseFloat(element.toFixed(2)), 0);
        setTotalCostOfProject(parseFloat(totalCost.toFixed(2)));
        
        const totalGST = projects.reduce((element, curr) => parseFloat(curr.gstValue) + parseFloat(element.toFixed(2)), 0);
        SetTotalGstValue(parseFloat(totalGST.toFixed(2)));
        getUserProfile();
    }else{
      SetIsShowMessage(true);
    }
  }
  // setIsRefreshed(true);
    
  };
  const closeModal = () =>{
    setIsItemEdit(false);
  } 

  const closeSaveModal = () => {
    setIsProjectsSaved(false);
  }

  // const GSTValues = [0, 5, 12, 18, 28];

  const conditionValues = ['Yes', 'No'];

  const months = [
    { value: '', label: 'Select a month' },
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

   // Define the range of years you want to include in the dropdown
   const startYear = new Date().getFullYear(); // Adjust as needed
  //  const endYear = new Date().getFullYear(); // Gets the current year
  const endYear = new Date().getFullYear() + periodOfLoan;
   // Generate an array of years within the specified range
   const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

   const moratorium = ['N/A', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

   const loanPeriod = [0, 1, 2, 3, 4, 5, 6, 7]; 

  const handleProjectEdit = (index) => {

    setIsItemEdit(true);
    // setIsAddItem(false);
    console.log("index: " + index)
    setProjectIndex(index);
    console.log(projectIndex);
    console.log(projects);
    setAboutEditProject(projects[index].aboutProject);
    setAmountOfEditProject(parseFloat(projects[index].amountOfProject));
    SetGstEditValue(parseFloat(projects[index].gstValue));
    
  }

  const borrowerId = BorrowerDetail.BorrowerId;

  console.log("BorrowerId: " + borrowerId);
  function handleEditProjectSave() {      // clicking on the save button after edit option
    const updatedProjects = [...projects];
    updatedProjects[projectIndex].aboutProject = aboutEditProject;
    updatedProjects[projectIndex].gstValue = gstEditValue;
    // setTotalCostOfProject(parseFloat(totalCostOfProject) - parseFloat(updatedProjects[projectIndex].amountOfProject));
    updatedProjects[projectIndex].amountOfProject = parseFloat(amountOfEditProject.toFixed(2));
    // updatedProjects[projectIndex].isActive = 1;
    // setTotalCostOfProject((totalCostOfProject+amountOfEditProject)*1.0);
    setProjects(updatedProjects);

    const totalCost = projects.reduce((element, curr) => parseFloat(curr.amountOfProject) + parseFloat(element.toFixed(2)), 0);
    setTotalCostOfProject(parseFloat(totalCost.toFixed(2)));
    
    const totalGST = projects.reduce((element, curr) => parseFloat(curr.gstValue) + parseFloat(element.toFixed(2)), 0);
    SetTotalGstValue(parseFloat(totalGST.toFixed(2)));
    closeModal();
  }

  const handleProjectDelete = async (index) => {

    const changedProjects = [...projects];
    // const amountOfProject = changedProjects[index].amountOfProject;
    // let totalCost = totalCostOfProject;
    // setTotalCostOfProject(totalCost-amountOfProject);
    

    let element; 
    console.log(element)
    element = {...changedProjects[index]};
    while(index < changedProjects.length){
      changedProjects[index] = changedProjects[index+1];
      console.log("index: "+ index );//+ " i: "+ i);
      index++;
    }
    // element = {...changedProjects.pop()};\
    changedProjects.pop();
    if(element.hasOwnProperty('isActive')){
        element.isActive = -1;
    }
    console.log(changedProjects);
    // deletedProjects.concat(element);
    setDeletedProjects(prevProjects => prevProjects ? [...prevProjects, element] : [element]);

    console.log(deletedProjects);
    const updatedProjects = [...changedProjects];
    setProjects(updatedProjects);
    // projects[index].isActive = -1;
    const totalCost = projects.reduce((element, curr) => parseFloat(curr.amountOfProject) + parseFloat(element.toFixed(2)), 0);
    setTotalCostOfProject(parseFloat(totalCost.toFixed(2)));
    
    const totalGST = projects.reduce((element, curr) => parseFloat(curr.gstValue) + parseFloat(element.toFixed(2)), 0);
    SetTotalGstValue(parseFloat(totalGST.toFixed(2)));
  }
  const addProduct = () => {
    // debugger
    const projectDetail = {
        productId: null,
        borrowerId,
        aboutProject: aboutProject,
        amountOfProject: parseFloat(amountOfProject.toFixed(2)),
        gstValue: parseFloat(gstValue),
        isActive: 0
    };

    // Update projects array
    setProjects(prevProjects => [...prevProjects, projectDetail]);

    // Update total cost of the project
    let amount = amountOfProject;
    setTotalCostOfProject(prevTotalCost => parseFloat( prevTotalCost ) + parseFloat(amount)); //+ parseFloat(gstValue.toFixed(2)));

    // Update total GST Value of the project
    let gst = gstValue;
    // SetTotalGstValue(prevGSTValue => parseFloat( prevGSTValue ) + parseFloat(gst));
    let totGstVal = totalGSTValue;
    SetTotalGstValue(parseFloat(totGstVal) + parseFloat(gst.toFixed(2)));

    // Reset aboutProject, amountOfProject and gstValue
    setAboutProject('');
    setAmountOfProject(0.0);
    SetGstValue(0.0);
    console.log(projects);
};

  // const addProduct = () => {
  //   const projectDetail = {
  //     productId: null,
  //     borrowerId,
  //     aboutProject,
  //     AmountOfProject: parseFloat(amountOfProject),
  //     isActive: 0
  //   };

  //   if(projects === undefined){
  //     setProjects([...projectDetail])
  //   }else{
  //     setProjects([...projects, projectDetail])
  //   }

  //   console.log(projects)

  //   // setProjects((prevProjects) => 
  //   // {
  //   //   if(prevProjects === undefined){
  //   //     return projectDetail;
  //   //   }else if(prevProjects.length >= 0) return [...prevProjects, projectDetail];

  //   // });
  //   setTotalCostOfProject((prevTotalCost) => parseFloat(prevTotalCost + projectDetail.AmountOfProject));
  //   setAboutProject('');
  //   setAmountOfProject(0.0);
 
  // };

  // const sourceOfFund = (e) => {
  //   // const marginMoneyPerc = parseFloat(e.target.value);
  //   setContributionAsMarginMoney(parseFloat(e.target.value));
  //   const loanAmountMoney = parseFloat(totalCostOfProject - ContributionAsMarginMoney);
  //   setMarginMoneyPercent(parseFloat(marginMoney/totalCostOfProject*100));
  //   setLoanPercentAmount(parseFloat(loanAmountMoney/totalCostOfProject*100));
  //   setLoanAmount(parseFloat(loanPercentAmount * totalCostOfProject / 100));
  //   setMarginMoney(parseFloat(marginMoneyPercent * totalCostOfProject / 100));
  // };

  const getUserProfile = async () => {
    try {
        if(BorrowerDetail.BorrowerId !== -1){
            let reqObj = {};
            reqObj.BorrowerId = BorrowerDetail.BorrowerId;
  
            const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getBorrowerProfile", {method: 'POST',
                mode: 'cors',
                headers:{
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqObj)
        }).then((response) => {
            // debugger
            if(response.ok)
            return response.json();
        }).then((data) => {
            if(data.Message === 'Success'){
                SetBorrowerProfile(data.Profile);
            }else if(data.Message === 'Failure'){
                SetIsShowNoDataMessage(true);
            }
        });
  
        console.log(response);
        }else{
          SetIsShowMessage(true);
        }
    } catch (error) {
        alert(error);
    }
  }

  function closeMessageModal(){
    SetIsShowMessage(false);
  }

  let SourceOfFund;
   const handleSave = async () => {
    try {
        // Set isAddItem to false if necessary
        // setIsAddItem(false);

        // Calculate SourceOfFund
        SourceOfFund = {
            MarginMoneyPercent: parseFloat(marginMoneyPercent.toFixed(2)),
            LoanAmountPercent: parseFloat(loanPercentAmount.toFixed(2)),
            ContributionAsMarginMoney: parseFloat((ContributionAsMarginMoney).toFixed(2)),
            ContributionAsLoanAmount: parseFloat((loanAmount).toFixed(2)),
        };

        let finalProjects = [...projects, ...deletedProjects];
        // finalProjects = finalProjects.map((element) => element.amountOfProject.toFixed(2).toString);
        for(let i = 0; i < finalProjects.length; i++){
          let value = parseFloat(finalProjects[i].amountOfProject);
          finalProjects[i].amountOfProject = parseFloat(value.toFixed(2));
        }
        // Construct borrowerDetails object
        const borrowerDetails = {
            BorrowerId: BorrowerDetail.BorrowerId,
            costOfProject: finalProjects,
            totalCostOfProjectWithGst: (parseFloat(totalCostOfProject) + parseFloat(totalGSTValue)).toFixed(2),
            totalCostOfProjectWithoutGst: parseFloat(totalCostOfProject).toFixed(2),
            SourceOfFund: SourceOfFund,
            InterestRateByBank: parseFloat(interestRateByBank.toFixed(2)),
            totalGSTValue: parseFloat(totalGSTValue.toFixed(2)),
            GSTITC: condVal,
            periodOfLoan: periodOfLoan,
            noOfInstallments: noOfInstallments,
            emiStartMonth: emiStartMonth,
            emiStartYear: emiStartYear,
            moratoriumValue: moratoriumValue
        };

        // Log borrowerDetails for debugging
        console.log("Borrower Details:", borrowerDetails);

        // Make POST request to server
        const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/addProduct", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(borrowerDetails)
        });

        // Log the response status
        console.log("Response Status:", response.status);
        // debugger
        // Check if response is ok
        if (response.ok) {
            const data = await response.json();
            console.log("Response Data:", data);

            setIsProjectsSaved(true);
            // debugger
            // Check if message in response is "Success"
            if (data.Message === "Success") {
              setIsProjectItemsSavedSuccess(true);
                console.log("Data: Success");
                setIsRefreshed(true);
            }else if(data.Message === "Failure"){
              setIsProjectItemsSavedSuccess(false);
            }
            return data;
        } else {
            throw new Error("Failed to fetch data");
        }
    } catch (error) {
        console.error('Error:', error);
        // Handle error appropriately, e.g., show an error message to the user
    }
}
//   async function handleSave() {
//     // setIsAddItem(false);
//     const costOfProjects = projects;
//     const SourceOfFund = {
//       MarginMoneyPercent: parseFloat(marginMoneyPercent),
//       LoanAmountPercent: parseFloat(loanPercentAmount),
//       ContributionAsMarginMoney: parseFloat(totalCostOfProject * (marginMoneyPercent / 100)),
//       ContributionAsLoanAmount: parseFloat(totalCostOfProject * (loanPercentAmount / 100)),
//     };

//     const borrowerDetails = {};
//     borrowerDetails.BorrowerId = BorrowerDetail.BorrowerId;
//     borrowerDetails.costOfProject =  costOfProjects;
//     borrowerDetails.totalCostOfProject = totalCostOfProject;
//     borrowerDetails.SourceOfFund = SourceOfFund;
//     borrowerDetails.InterestRateByBank = interestRateByBank;
//     borrowerDetails.periodOfLoan = periodOfLoan;
//     borrowerDetails.noOfInstallments = noOfInstallments;
//     borrowerDetails.emiStartMonth = emiStartMonth;
//     borrowerDetails.emiStartYear = emiStartYear;
//     // const borrowerDetails = {
//     //   costOfProject: costOfProjects,
//     //   totalCostOfProject,
//     //   SourceOfFund,
//     //   InterestRateByBank: interestRateByBank,
//     //   periodOfLoan,
//     //   noOfInstallments,
//     //   emiStartMonth,
//     //   emiStartYear
//     // };

//     // borrowerDetailS = setBorrowerDetails(borrowerDetails);


//     console.log(borrowerDetails);
//     // Make POST request to server
//     const response = await fetch("http://localhost:8080/api/v1/creditMonitoringAnalysis/addProduct", {
//       method: "POST",
//       mode: "cors",
//       headers: {
//           "Content-Type": "application/json",
//       },
//       body: JSON.stringify(borrowerDetails)
//   });

//   // Log the response
//   console.log(response);

//   // Check if response is ok
//   if (response.ok) {
//       console.log("Success");
//   } else {
//       console.log("Failure");
//   }

//   // Parse response JSON
//   const data = await response.json();
//   console.log(data.body);

//   // Check if message in response is "Success"
//   if (data.Message === "Success") {
//       console.log("Data: Success");
//   }

//   return data;
// // } catch (error) {
// //   console.error('Error:', error);
// //   // Handle error appropriately, e.g., show an error message to the user
// // }
// }
  //   let response;
  //   response = await fetch("http://localhost:8080/api/v1/creditMonitoringAnalysis/addProduct",{
  //       method:"POST",
  //       mode:"cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(borrowerDetails)
  //   }).then((response) =>
  //  { 
  //   console.log(response);
  //   if(response.ok){
  //     console.log("Success");
  //   }else{
  //     console.log("Failure");
  //   }
  //   return response.json();
  //  }
  // ).then((data) => {
  //   console.log(data.body);
  //   if(data.Message === "Success"){
  //     console.log("Data: Success");
  //   }
  //   return data;
  // });
  // console.log(response);

  const refreshProjects = () => {

    const contributionValue = parseFloat(ContributionAsMarginMoney);
    const totalCostWithGST = parseFloat(totalCostOfProject) + parseFloat(totalGSTValue);
    const loanAmountMoney = parseFloat((totalCostWithGST - contributionValue).toFixed(2));

    // Update ContributionAsMarginMoney
    setContributionAsMarginMoney(contributionValue);

    // Calculate marginMoneyPercent and update
    const marginPercent = parseFloat((contributionValue / totalCostWithGST * 100).toFixed(2));
    setMarginMoneyPercent(marginPercent);

    // Calculate loanPercentAmount and update
    const loanPercent = parseFloat((loanAmountMoney / totalCostWithGST * 100).toFixed(2));
    setLoanPercentAmount(loanPercent);

    // Calculate loanAmount and update
    // const loanAmount = parseFloat((loanPercent * totalCostWithGST / 100).toFixed(2));
    setLoanAmount(loanAmountMoney);
    // // const loanAmountMoney = parseFloat(totalCostOfProject) + parseFloat(totalGSTValue) -parseFloat(ContributionAsMarginMoney);
    // const loanAmountMoney = parseFloat((parseFloat(totalCostOfProject) + parseFloat(totalGSTValue) - parseFloat(ContributionAsMarginMoney)).toFixed(2));
    // setContributionAsMarginMoney(parseFloat(ContributionAsMarginMoney.toFixed(2)));
    // setMarginMoneyPercent(parseFloat(parseFloat(ContributionAsMarginMoney/(parseFloat(totalCostOfProject)+parseFloat(totalGSTValue))*100).toFixed(2)));
    // setLoanPercentAmount(parseFloat((parseFloat(loanAmountMoney)/(parseFloat(totalCostOfProject)+parseFloat(totalGSTValue))*100).toFixed(2)));
    // setLoanAmount(parseFloat((parseFloat(loanPercentAmount) * (parseFloat(totalCostOfProject)+parseFloat(totalGSTValue)) / 100).toFixed(2)));

    // // setMarginMoneyPercent(parseFloat(parseFloat(ContributionAsMarginMoney).toFixed(2)) / parseFloat(parseFloat((parseFloat(totalCostOfProject) + parseFloat(totalGSTValue)).toFixed(2)) * 100));
    // // setLoanPercentAmount(parseFloat((parseFloat(loanAmountMoney.toFixed(2))/parseFloat(parseFloat((parseFloat(totalCostOfProject) + parseFloat(totalGSTValue)).toFixed(2)) * 100).toFixed(2))));
    // // setLoanAmount(parseFloat((parseFloat(parseFloat(loanPercentAmount).toFixed(2)) * parseFloat(parseFloat((parseFloat(totalCostOfProject) + parseFloat(totalGSTValue))).toFixed(2)) / 100).toFixed(2)));
    const totalCost = projects.reduce((element, curr) => parseFloat(curr.amountOfProject) + parseFloat(element), 0);
    setTotalCostOfProject(parseFloat(totalCost.toFixed(2)));
    const totalGST = projects.reduce((element, curr) => parseFloat(curr.gstValue) + parseFloat(element), 0);
    SetTotalGstValue(parseFloat(totalGST.toFixed(2)));
    if(!isRefreshed){
      // enterProduct();
      setIsRefreshed(true);
    }    
  }
 
  const ShowCostOfProject = async () => {
    try {
      SetIsShowEnterItem(flag);
      SetIsShowStartPoints(flag);
      SetIsShowEditNdelete(flag);
      SetIsShowSourceOfFund(flag);
      // SetIsShowGstPer(!flag);
      // SetIsShowGst(flag);
      // SetIsShowSourcesOfFund(!flag);
      setFlag(!flag);
      let reqBody = {};
      reqBody.BorrowerId = BorrowerDetail.BorrowerId;
      const response = await fetch("https://lblpr.com/api/v1/creditMonitoringAnalysis/getSourceOfFunds", {
        method: "POST",
        mode: 'cors',
        headers:{
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
    }).then((response) => {
      if(response.ok){
        return response.json();
      }
    }).then((data) => {
      if(data.Message === 'Success'){
        SetSourcesOfFunds(data.SourceOfFunds);
        SetEmiPeriodDetails(data.EmiPeriodDetails);
        setTotalCostOfProject(data.SourceOfFunds.totalCostOfProjectWithoutGst);
        SetTotalGstValue(data.SourceOfFunds.totalGstValue);
      }else if(data.Message === 'Failure'){
        SetIsShowNoDataMessage(true);
      }
    });
    console.log(response);
    } catch (error) {
      alert(error);
    }
  }

  function closeNoDataModal(){
    SetIsShowNoDataMessage(false);
  }

  // function handlePrint(){
  //   window.print();
  // }
  

  return (
    <>
    <div>{isShowHiddingButton && (<>
        <button 
      style={{ backgroundColor: 'lightblue', color: 'green', borderRadius: '5px' }}
      onClick={ShowCostOfProject}>...</button><br/>
      
      <button onClick={generatePDF} style={{ backgroundColor: 'ligtblue', width: '120px', height: '40px', color: 'Black', borderRadius: '5px' }}>DownloadAsPdf</button><br/>
      </>)}</div>

      {isShowEnterItem && (
      <button
        style={{ backgroundColor: 'blue', width: '60px', height: '40px', color: 'white', borderRadius: '5px' }}
        onClick={enterProduct}
      >
        Enter Item
      </button>)}
    <div ref={contentRef} style={{border:'3px solid black', borderRadius:'5px', padding: '10px 5px', margin: '10px 5px'}}>
      <h3 style={{textAlign:"center"}}>{borrowerProfile.borrower_sFirmName}</h3>
            <h4 style={{textAlign:"center"}}>Borrower:&nbsp; {borrowerProfile.borrowerName}</h4>
            <h4 style={{textAlign:"center"}}>Address of unit:  {borrowerProfile.borrowerRegisteredAddress}</h4>
            <h4 style={{textAlign:"center"}}>Purpose Of Loan:  {borrowerProfile.purpose_of_Loan}</h4>
      <br/>
            <span><b>COST OF THE PROJECT</b></span>
            <br/>

      {isAddItem && (
        <div>
          {isShowStartPoints && (<>
         <button
        style={{ backgroundColor: 'lightgreen', width: '60px', height: '40px', color: 'Black', borderRadius: '5px' }}
        onClick={refreshProjects}
      >Refresh</button><br/>
            {/* <span>About the Project</span> */}
            <span>The Project Items:- </span>
          <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={aboutProject}
            onChange={(e) => setAboutProject(e.target.value)}
          />
          {/* <br /> */}

          <span>Amount (Rs.)</span>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={amountOfProject}
            type='number'
            step="0.01"
            min={0}
            onChange={(e) => setAmountOfProject(parseFloat(e.target.value))}
          />

          <span>GST Value (Rs.)</span>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={gstValue}
            type='number'
            step="0.01"
            min={0}
            onChange={(e) => SetGstValue(parseFloat(e.target.value))}
          />
          <button
            style={{ backgroundColor: 'blue', width: '60px', height: '40px', color: 'white', borderRadius: '5px' }}
            onClick={addProduct}
          >
            Add Item
          </button>
          </>)}
          {(<div>
          <table>
            <thead>
                <tr style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>
                    <th>Particulars</th>
                    <th>Amount (Rs.)</th>
                    <th>GST Value (RS.)</th>
                </tr>
            </thead>
            {/* <t key='About Project'>About Project&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amount(Rs.)</li> */}
            {/* <button style = {{ backgroundColor: 'blue', width: '60px', height: '40px', color: 'white', borderRadius: '5px' }} onChange={handleAboutProjectEdit}>Edit Projects</button> */}
            <tbody>
              {/* {isShowGst && (<>
              <span style={{ border: '1px solid black', width: "100px", backgroundColor: 'lightblue'}}>GST</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <select value={gstValue} onChange={(e) => SetGstValue(e.target.value)}>

          
{GSTValues.map((GstValue, index) => (
  <option key={index} value={GstValue}>{GstValue}</option>
))}
</select></>)} */}

                {(isShowProject) && (
               projects.map((project, index) => (
                <tr key={index}>
                      <td style={{ width: "100px"}}>{project.aboutProject}</td>
                      <td style={{ width: "100px"}}>{project.amountOfProject}</td>
                      <td style={{ width: "100px"}}>{project.gstValue}</td>
                      {/* <td><img src={editLogo} alt='edit' width="100" height="100" /></td> */}
                      
                      {isShowEditNdelete && (<>
                      <td >
                      <div className="tooltip">
                        <button className="btn-hover" style = {{ backgroundColor: 'white', width: '60px', height: '20px', color: 'red', borderRadius: '5px' }} onClick={() => handleProjectEdit(index)}><SlPencil /></button>
                        <span className="tooltiptext">Edit</span>
                      </div>
                        </td>
                      <td >
                      <div className="tooltip">
                        <button className='btn-hover' style = {{ backgroundColor: 'white', width: '60px', height: '20px', color: 'black', borderRadius: '5px' }} onClick={() => handleProjectDelete(index)}><SlTrash /></button>
                       <span className="tooltiptext">Delete</span>
                      </div> 
                        </td>
                      </>)}
                    </tr>
               )))
               
                }

{/* {isShowGstPer && (<>
            <span style={{ border: '1px solid black', width: "100px", backgroundColor: 'lightblue'}}>GST</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{emiPeriodDetails.gstValue}</span>
            <br/>
            <br/>
            </>
          )} */}
            <tr key='totalCost'>
            <td style={{width: "100px"}}><b>Total Cost Of Project</b></td>
            <td style={{width: "100px"}}>{parseFloat((parseFloat(totalCostOfProject) + parseFloat(totalGSTValue)).toFixed(2))}</td>
            </tr>
            </tbody>
            </table>
            
           {/* <span>Total Cost Of Project= </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{totalCostOfProject}            */}

          <h4>SOURCES OF FUND</h4>
          {isShowSourceOfFund && (
            <>
          <br />
          <span>Contribution &nbsp; 
            <input
            style={{ width: '80px', height: '10px', borderRadius: '5px', padding: '5px' }}
            value={ContributionAsMarginMoney}
            onChange={(e) => {
              // debugger
              const contributionValue = parseFloat(parseFloat(e.target.value).toFixed(2));
    const totalCostWithGST = parseFloat(totalCostOfProject) + parseFloat(totalGSTValue);
    const loanAmountMoney = parseFloat((totalCostWithGST - contributionValue).toFixed(2));

    // Update ContributionAsMarginMoney
    setContributionAsMarginMoney(contributionValue);

    // Calculate marginMoneyPercent and update
    const marginPercent = parseFloat((contributionValue / totalCostWithGST * 100).toFixed(2));
    setMarginMoneyPercent(marginPercent);

    // Calculate loanPercentAmount and update
    const loanPercent = parseFloat((loanAmountMoney / totalCostWithGST * 100).toFixed(2));
    setLoanPercentAmount(loanPercent);

    // Calculate loanAmount and update
    // const loanAmount = parseFloat((loanPercent * totalCostWithGST / 100).toFixed(2));
    setLoanAmount(loanAmountMoney);
            }}
            type='number'
          /> as Margin Money @ {marginMoneyPercent} %</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <u>{ContributionAsMarginMoney}</u>
          <br /> <br/>
          <span>Loan Amount @ {loanPercentAmount} %</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <u>{loanAmount}</u>
          <br/><br/>
          <div>Interest Rate Charged By the Bank 
          <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '5px' }}
            value={interestRateByBank}
            step="0.01"
            type='number'
            onChange={(e) => setInterestRateByBank(parseFloat(e.target.value))}
          /> </div>
          <br/>
          <div>Period Of Loan (Years / Annum)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '5px' }}
            value={periodOfLoan}
            type='number'
            onChange={(e) => setPeriodOfLoan(parseInt(e.target.value))}
          /> */}
          <select value={periodOfLoan} onChange={(e) => {setPeriodOfLoan(e.target.value)
            setNoOfInstallments(parseInt(e.target.value) * 12);
          }}>
          {loanPeriod.map((period, index) => (
          <option key={index} value={period}>{period}</option>
          ))}
          </select>
          </div><br/>
          <div>No. Of Installments (Months) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '5px' }}
            value={noOfInstallments}
            type='number'
            onChange={(e) => setNoOfInstallments(parseInt(e.target.value))}
          />  */}
          {noOfInstallments}
          </div><br/>
          <br />
          <br />

          <span>EMI Starting Month:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <select value={emiStartMonth} onChange={(e) => SetEmiStartMonth(e.target.value)}>

          {months.map((month, index) => (
            <option key={index} value={month.label}>{month.label}</option>
          ))}

          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <select value={emiStartYear} onChange={(e) => SetEmiStartYear(e.target.value)}>

          
          {years.map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
          </select>

          <br/>
          <br/>
          <br/>

          <span>Moratorium:-</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <select value={moratoriumValue} onChange={(e) => SetMoratoriumValue(e.target.value)}>
          
          {
            moratorium.map((morator, index) => (
              <option key ={index} value={morator}>{morator}</option>
            ))
          }

          </select>

          <br/>
          <br/>
          <br/> 

          <span>Whether GST ITC will be availed</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <select value={condVal} onChange={(e) => SetCondVal(e.target.value)}>

           {
            conditionValues.map((conditionValue, index) => (
              <option key={index} value={conditionValue}>{conditionValue}</option>
            ))
           }

          </select>

          <br/>
          <br/>
          <br/>

          <button
            style={{ width: '100px', height: '45px', borderRadius: '5px', padding: '15px', textAlign: 'center' }}
            onClick={handleSave}
          >
            Save
          </button>

          {/* <RepaymentSchedule props = {borrowerDetailS}/> */}

          </>)}
        </div>
      )}

     {/* {isShowSourcesOfFund && ( */}
      <div>
      <span>Own Fund</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{sourcesOfFunds.marginContribution}</span>
      <br/>
      <br/>
      <span>Loan Fund</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{sourcesOfFunds.bankLoan}</span>
      <br/>
      <br/>
      <span>Interest Rate By Bank</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{sourcesOfFunds.interestRateByBank}</span>
      <br/>
      <br/>
      <span>Period Of Loan</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{sourcesOfFunds.year}</span>
      <br/>
      <br/>
      <span>No Of Installment</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{sourcesOfFunds.period}</span>
      <br/>
      <br/>
      <span>EMI Start Month and Year</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{emiPeriodDetails.emiStartMonth}&nbsp;&nbsp;{emiPeriodDetails.emiStartYear}</span>
      <br/>
      <br/>
      <span>Moratorium Months:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{emiPeriodDetails.moratoriumMonths}</span>
      <br/>
      <br/>
      <span>Whether GST ITC Availed:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{emiPeriodDetails.gstItc}</span>
      <br/>
      <br/>
      </div>
     {/* )} */}
     </div>)}
     


      {isItemEdit && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>Edit Project</h2>
            <input
            style={{ width: '300px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={aboutEditProject}
            onChange={(e) => setAboutEditProject(e.target.value)}
          />
          {/* <br /> */}

          <span>Amount Rs.</span>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={amountOfEditProject}
            type='number'
            step="0.01"
            onChange={(e) => setAmountOfEditProject(parseFloat(e.target.value))}
          />

          <span>GST Value (Rs.)</span>
          <input
            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px' }}
            value={gstEditValue}
            type='number'
            step="0.01"
            min={0}
            onChange={(e) => SetGstEditValue(parseFloat(e.target.value))}
          />

          <br/>
          <br/>

          <button onClick={handleEditProjectSave}>Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={closeModal}>Cancel</button>
          </div>
        </div>)}

        {isProjectsSaved && (<div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={closeSaveModal}>&times;</span>
            {isProjectItemsSavedSuccess ? <><h2 style={{color:"green"}}>Saved Successfully!!<br/><br/><br/><center><FcApproval /></center></h2>
            <center><button onClick={closeSaveModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></>:<><h2 style={{color:"red"}}>Saving Failed!!<br/><br/><br/><br/><center><ImCross /></center></h2>
            <center><button onClick={closeSaveModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></>}
            

          <br/>
          <br/>
          </div>
        </div>)}
        {isShowMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

{isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
    </div>
    </>
  );
}

export default CostOfProject;